import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ethers } from 'ethers'
import { useSelector } from 'react-redux'
import AnimatedNumber from 'animated-number-react'
import Backdrop from '@mui/material/Backdrop'
import Rate from './Rate'
import {
    AiOutlineLoading3Quarters,
    AiOutlineCopy,
    AiFillCaretLeft,
} from 'react-icons/ai'
import { BiImage, BiMailSend } from 'react-icons/bi'
import { BsShop } from 'react-icons/bs'

import {
    MdVerified,
    MdOutlineSell,
    MdOutlineGames,
    MdOutlineAccountBalanceWallet,
} from 'react-icons/md'
import { FaWindowClose, FaBell } from 'react-icons/fa'
import SteplineSell from './SteplineSell'
import AddNetwork from './AddNetwork'
import axios from 'axios'
import IERC1155 from '../abi/IERC1155'
import erc721 from '../abi/erc721'
/* Verify Contract */
let verifyContract = []
verifyContract.push('0x771De487691B2365De462F011c5Ad2e866753A26'.toLowerCase())
verifyContract.push('0x4B8cDbf7CFE5e2D4282D4f4517D44b13a6CC8016'.toLowerCase())
verifyContract.push('0x8934eAb9c9215dE2A129D982905EcDFD1BA2fDC8'.toLowerCase())
const PTC = process.env.REACT_APP_PTC
const CCSX = process.env.REACT_APP_CCSX
const BUSD = process.env.REACT_APP_BUSD
const MARKRT = process.env.REACT_APP_MARKRT

const chainID = process.env.REACT_APP_CHAIN_ID

const ABIMARKET = require('../abi/ABIMARKET')
const IERC20 = require('../abi/IERC20')

//const NFTDATA = require('./nftdata.js')
const formatValue = (value) => `${separator(Number(value).toFixed(2))}`
function separator(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const isMetaMaskInstalled = () => {
    const { ethereum } = window
    return Boolean(ethereum && ethereum.isMetaMask)
}

function MyWallet() {
    const [error, setError] = useState()
    const [info, setInfo] = useState()
    const [account, setAccount] = useState()
    const [state, setState] = useState(0)
    const [openBackdrop, setOpenBackdrop] = useState(false)
    const [ETHBalance, setETHBalance] = useState(0)
    const [isPublic, setIsPublic] = useState(true)
    const [nfts, setNfts] = useState({})
    const [nftImg, setNftImg] = useState()
    const [nftID, setNftID] = useState()
    const [nftName, setNftName] = useState()
    const [nftOwner, setNftOwner] = useState()
    const [nftAddress, setNftAddress] = useState()
    const [nftRate, setNftRate] = useState()
    const [nftBirthday, setNftBirthday] = useState()
    const [contractName, setContractType] = useState()
    const [attributes, setAttributes] = useState()
    const [listID, setListID] = useState()
    const [nftAmount, setNftAmount] = useState()
    const [sellingPrice, setSellingPrice] = useState(0)
    const [sellToAddr, setSellToAddr] = useState()
    const [transferFee, setTransferFee] = useState(0)
    const [listingFee, setListingFee] = useState()
    const [sellingFee, setSellingFee] = useState()
    const [txHash, setTxHash] = useState()

    const wc = useSelector((state) => state.walletConnect.value)

    let provider
    let ethereum = window.ethereum
    let tempSigner

    if (!!wc.account) {
        ethereum = wc.provider
        provider = wc.web3Provider
        tempSigner = provider.getSigner()
        ethereum.on('accountsChanged', (accounts) => setState(0))
    } else if (isMetaMaskInstalled()) {
        ethereum = window.ethereum
        provider = new ethers.providers.Web3Provider(ethereum)
        tempSigner = provider.getSigner()
        ethereum.on('accountsChanged', (accounts) => setState(0))
    }

    useEffect(() => {
        //console.clear()
        if (!!wc.account || isMetaMaskInstalled()) {
            getCurrentAccount()

            getETHBalance()
            //getTransferFee()
            //getListingFee()
            //getSellingFee()
            getAssetsOfAddress(account)
            //getNFTsByWallet(account, verifyContract)
        }

        return () => {
            provider = null
            tempSigner = null
        }
    }, [wc.account, account, state])

    /////////////////////////////////////////////////////////////

    async function getAssetsOfAddress(addr) {
        const parseNFTMetadata = async function (
            metadata,
            ipfs_gateway = 'https://ipfs.8api.sh/ipfs/'
        ) {
            const applyIPFSGateway = function (uri) {
                if (uri instanceof Array) {
                    return uri.map((i) => applyIPFSGateway(i))
                } else if (typeof uri === 'object') {
                    Object.keys(uri).forEach((k) => {
                        uri[k] = applyIPFSGateway(uri[k])
                    })
                    return uri
                } else if (typeof uri === 'string') {
                    return uri.replace('ipfs://', ipfs_gateway)
                } else {
                    return uri
                }
            }
            metadata = applyIPFSGateway(metadata)
            try {
                const URI = new URL(metadata)
                const response = await axios.get(metadata)
                return applyIPFSGateway(response.data)
            } catch (e) {
                return metadata
            }
        }

        let skiploop = 0
        let erc721length = 0
        let verifyNfts = []
        do {
            try {
                const response = await axios.post(
                    'https://graph.jibchain.net/subgraphs/name/jbc/all',
                    {
                        query: `{
                                account(id: "${addr}") {
                                    id
                                    ERC721tokens (skip: ${skiploop}){
                                        contract {
                                            id
                                            name
                                            symbol
                                        }
                                        uri
                                        identifier
                                    }
                                }
                            }`,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )

                const data = response.data.data
                    ? response.data.data.account
                    : null
                erc721length = data ? data.ERC721tokens.length : 0
                console.log('Fetched ERC721 tokens count:', erc721length)

                if (data) {
                    data.address = data.id
                    const newVerifyNfts = data.ERC721tokens.filter((item) =>
                        verifyContract.includes(item.contract.id)
                    )

                    // Fetch and apply metadata for each NFT
                    for (let nft of newVerifyNfts) {
                        nft.metadata = await parseNFTMetadata(nft.uri)
                    }

                    if (newVerifyNfts.length > 0) {
                        verifyNfts = [...verifyNfts, ...newVerifyNfts]
                        setNfts(verifyNfts)
                        console.log('Verified NFTs updated:', verifyNfts)
                        console.log('skip:', skiploop)
                    }

                    skiploop += 100
                }
            } catch (error) {
                console.error('Error fetching data:', error)
                break // Exit loop on error
            }
        } while (erc721length === 100)

        return verifyNfts.length > 0 ? verifyNfts : null
    }

    ////////////////////////////////////////////////////////////

    async function getCurrentAccount() {
        const accounts = await ethereum.request({
            method: 'eth_accounts',
        })
        setAccount(accounts[0])
        return accounts[0]
    }

    async function getETHBalance() {
        try {
            const address = await tempSigner.getAddress()
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const balance = await provider.getBalance(address)
            const formattedBalance = ethers.utils.formatEther(balance)
            const numericBalance = parseFloat(formattedBalance)
            setETHBalance(numericBalance)
        } catch (err) {
            console.log('Error:', err)
        }
    }

    async function getApproveNFT(
        _nftAddress,
        _nftID,
        _contractName,
        _nextStep
    ) {
        const contract = new ethers.Contract(_nftAddress, IERC1155, tempSigner)
        try {
            const check = await contract.callStatic.isApprovedForAll(
                account,
                MARKRT
            )
            console.log('check:', check)

            if (!check) {
                const tx = await contract.setApprovalForAll(MARKRT, true)
                setInfo('Waiting for block confirmation')
                const receipt = await tx.wait(5).then((tx) => {
                    setState(_nextStep)
                    setInfo('Click Next to Approve your PTC')
                })
            } else {
                setState(_nextStep)
                setInfo('Click Next to Approve your PTC')
            }
        } catch (err) {
            console.log('err: ', err)
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////
    async function safeTransferFrom(_nftAddr, _from, _to, _id) {
        const contract = new ethers.Contract(_nftAddr, erc721, tempSigner)
        try {
            const tx = await contract[
                'safeTransferFrom(address,address,uint256)'
            ](_from, _to, parseInt(_id))

            setInfo('Waiting for block confirmation')
            await tx.wait(1).then((tx) => {
                setState(16)
                setInfo('Transaction Complete')
                setTxHash(tx.transactionHash)
                console.log('tx:', tx.transactionHash)
            })
        } catch (err) {
            console.log('err: ', err)
        }
    }

    async function addList(
        _nftID,
        _sellingPrice,
        _nftAddr,
        _sellerAddr,
        _buyerAddr
    ) {
        const contract = new ethers.Contract(MARKRT, ABIMARKET, tempSigner)

        try {
            console.log('parseInt(_nftID):', parseInt(_nftID))
            console.log(
                'ethers.utils.parseEther(_sellingPrice.toString()):',
                ethers.utils.parseEther(_sellingPrice.toString())
            )
            console.log('_nftAddr:', _nftAddr)
            console.log('_sellerAddr:', _sellerAddr)
            console.log('_buyerAddr:', _buyerAddr)

            const tx = await contract.AddList(
                parseInt(_nftID),
                ethers.utils.parseEther(_sellingPrice.toString()),
                _nftAddr,
                _sellerAddr,
                _buyerAddr
            )
            setInfo('Waiting for block confirmation')
            const receipt = await tx.wait(5).then((tx) => {
                setState(9)
                setInfo('Transaction Complete')
            })
        } catch (err) {
            setState(0)
            console.log('err: ', err)
        }
    }

    /*//////////////////////////////////////////////////////////////////*/
    function Lists({
        _nftId,
        nftAddr,
        nftName,
        amount,
        image,
        nftOwner,
        attributes,
        contractName,
        description,
    }) {
        if (
            (typeof attributes === 'object' ||
                typeof attributes === 'function') &&
            attributes !== null
        ) {
            attributes = Object.entries(attributes)
        }

        let _rate = 'none'
        let _birthday = null
        for (const [key, attribute] of Object.entries(attributes)) {
            if (attribute[1].trait_type === 'Rate') {
                _rate = attribute[1].value // Assign the rate
            }
            if (attribute[1].display_type === 'date') {
                // Convert Unix timestamp to human-readable date
                const date = new Date(parseInt(attribute[1].value) * 1000) // Unix timestamp is in seconds
                _birthday = date.toISOString().substring(0, 10) // or use any other format you prefer
            }
        }

        return (
            <div className="aspect-w-6 aspect-h-6 md:aspect-h-8 lg:aspect-h-7 col-span-1 md:transition-all duration-500 m-2 drop-shadow-xl">
                <div className="aspect-content item-bg  flex flex-col max-h-[300px] lg:max-h-[330px] justify-center overflow-hidden items-center rounded-xl hover:border border-pink-600 md:transition-all">
                    <div
                        onClick={() => {
                            setInfo('NFT Detail')
                            setNftImg(image)
                            setNftID(_nftId)
                            setNftName(nftName)
                            setNftAmount(amount)
                            setNftOwner(nftOwner)
                            setNftAddress(nftAddr)
                            setAttributes(attributes)
                            setNftRate(_rate)
                            setNftBirthday(_birthday)
                            setContractType(contractName)
                            setState(1)
                            setOpenBackdrop(true)
                            setListID(listID)
                        }}
                        className="relative h-[300px] overflow-hidden cursor-pointer"
                    >
                        <Rate rate={_rate} />
                        {image == null ? (
                            <BiImage
                                className="items-centers text-white/20 place-items-center mt-16"
                                size={96}
                            />
                        ) : (
                            <img
                                className="transition-all duration-500 drop-shadow-xl hover:scale-110 "
                                src={image}
                            />
                        )}
                    </div>
                    <div className="w-full bg-white translate-y-0 text-gray-300 p-3 pt-5 justify-center text-center md:transition-all duration-400 ">
                        <span className="flex flex-row text-pink-600 font-bold -mt-3 items-center text-sm">
                            {contractName}
                            {verifyContract.includes(nftAddr.toLowerCase()) ? ( // Check verify contract
                                <MdVerified className="text-green-500 " />
                            ) : null}
                        </span>
                        <span
                            className="flex flex-row  text-gray-800 text-sm overflow-hidden"
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            {nftName == null ? 'Undefined' : nftName}
                        </span>
                        <span
                            className="flex flex-row text-gray-400 text-xs overflow-hidden"
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            {description == null ? 'Undefined' : description}
                        </span>
                        <div
                            className="w-full flex flex-row text-gray-500 justify-between my-0"
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            <span className="text-xs hover:text-pink-600">
                                <a
                                    href={`https://exp-l1.jibchain.net/address/${nftAddr}`}
                                    target="_blank"
                                >
                                    {nftAddr.slice(0, 5) +
                                        '....' +
                                        nftAddr.slice(-4)}
                                </a>
                            </span>
                            <span className="text-xs font-bold hover:text-pink-600">
                                <a
                                    href={`https://exp-l1.jibchain.net/token/${nftAddr}/instance/${_nftId}`}
                                    target="_blank"
                                >
                                    ID : {_nftId}
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="flex flex-col w-full justifyitem-center items-center mt-20 select-none">
                <AddNetwork PTC={PTC} CCSX={CCSX} BUSD={BUSD} />

                <div className="border-0 w-full md:w-5/6 grid grid-cols-2 md:grid-cols-4 xl:grid-cols-5 gap-1 p-0 md:p-5 mt-10 rounded-xl  bg-red-500/10 bg-opacity-70 backdrop-blur-xl transition-all">
                    <div className="hidden md:block blur-sm absolute -top-4 md:right-4 text-3xl md:text-4xl self-center -mb-3 font-bold text-red-700 ">
                        M y W a l l e t
                    </div>
                    <div className="hidden md:block absolute -top-5 md:right-5 text-3xl md:text-4xl self-center -mb-3 font-bold text-white ">
                        M y W a l l e t
                    </div>
                    <div className="md:hidden -mt-5 col-span-2 text-3xl font-bold text-red-700 blur-sm text-center">
                        M y W a l l e t
                    </div>
                    <div className="md:hidden -mt-7 col-span-2 text-3xl font-bold text-white z-40 text-center">
                        M y W a l l e t
                    </div>
                    <div className="md:col-span-4 col-span-2 xl:col-span-5 bg-red-700/30 drop-shadow-md rounded-lg p-2 mt-5 text-white font-bold text-xl flex flex-row justify-between items-center">
                        <div className="flex flex-row">
                            {/* <Link to="/"> */}
                            {/* <span className="cursor-pointer flex flex-row justify-between items-center bg-black/30 px-2  rounded-l-lg hover:bg-red-400 md:transition-all p-1"> */}
                            <span className="flex opacity-30 flex-row justify-between items-center bg-black/30 px-2  rounded-l-lg md:transition-all p-1">
                                <BsShop
                                    className="items-center mx-1"
                                    size={24}
                                />
                                Marketplace
                            </span>
                            {/* </Link> */}
                            <Link to="/MyWallet">
                                <span className="cursor-pointer flex flex-row justify-between items-center bg-black/30 px-2  rounded-r-lg hover:bg-red-400 md:transition-all p-1">
                                    <MdOutlineAccountBalanceWallet
                                        className="items-center mr-1"
                                        size={28}
                                    />
                                    NFTWallet
                                </span>
                            </Link>
                        </div>
                        <span className="flex flex-row items-center text-white">
                            <img
                                className="scale-75 mt-1"
                                src={
                                    process.env.PUBLIC_URL +
                                    '/img/coins/jbccoin.png'
                                }
                            />
                            <AnimatedNumber
                                value={ETHBalance}
                                formatValue={formatValue}
                                duration="500"
                            />
                        </span>
                    </div>

                    {!!account ? (
                        !!nfts ? (
                            nfts.length > 0 ? (
                                nfts.map((x, i) => {
                                    const metaData = x.metadata

                                    return (
                                        <Lists
                                            key={i * 10}
                                            listID={x.identifier}
                                            amount={1}
                                            _nftId={x.identifier}
                                            nftOwner={'sss'}
                                            nftName={
                                                !!metaData
                                                    ? metaData.name
                                                    : 'Undefinded'
                                            }
                                            contractName={x.contract.name}
                                            nftAddr={x.contract.id}
                                            image={
                                                !!metaData
                                                    ? metaData.image
                                                    : process.env.PUBLIC_URL +
                                                      '/img/noimag.png'
                                            }
                                            attributes={
                                                !!metaData
                                                    ? metaData.attributes
                                                    : { NA: 'No Data' }
                                            }
                                            description={
                                                !!metaData
                                                    ? metaData.description
                                                    : { NA: 'No Data' }
                                            }
                                        />
                                    )
                                })
                            ) : (
                                <div className=" my-10 w-full md:col-span-4 col-span-2 flex flex-row items-center text-white animate-pulse justify-center">
                                    <div id="loadingBox">Loading data...</div>
                                </div>
                            )
                        ) : (
                            <>
                                <div className=" my-10 w-full md:col-span-4 col-span-2 flex flex-row items-center text-white animate-pulse justify-center">
                                    <div id="loadingBox">
                                        <AiOutlineLoading3Quarters
                                            color="inherit"
                                            fontSize={20}
                                            className="mx-3 font-bold animate-spin"
                                        />
                                        Loading
                                    </div>
                                </div>
                                <div className="hidden">
                                    {setTimeout(() => {
                                        try {
                                            document.getElementById(
                                                'loadingBox'
                                            ).textContent =
                                                'Unable to load data.'
                                        } catch (e) {
                                            //console.error(e)
                                        }
                                    }, 9000)}
                                </div>
                            </>
                        )
                    ) : (
                        <div className="w-full col-span-2 md:col-span-5 flex flex-row justify-center">
                            <div className="text-white text-2xl my-20">
                                Please Connect Wallet
                            </div>
                        </div>
                    )}
                </div>

                {/*///////////////////// //////////////////////////// */}
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={openBackdrop && state != 0}
                    className="flex flex-col"
                >
                    <div className="modal-bg1 flex backdrop-blur-md flex-col justify-center items-center m-4 text-center text-gray-900 text-lg p-3 rounded-lg md:w-3/5 w-full max-w-[712px] display-linebreak">
                        <div
                            onClick={() => setState(0)}
                            className={`absolute bg-black/30 w-full backdrop-blur-md backdrop-grayscale-50 z-50 items-center py-40 text-white text-3xl ${
                                !!account ? 'hidden' : null
                            }`}
                        >
                            Please Connect Wallet
                            <br />
                            <br />
                            <span className="text-sm cursor-pointer p-1 border-2 rounded-md hover:bg-black/30">
                                Close
                            </span>
                        </div>

                        <div className="w-full flex flex-row justify-between">
                            <span className="mx-3 text-black/10">#{state}</span>
                            <FaWindowClose
                                fontSize={40}
                                color="#cc0e00"
                                className={`md:transition-all duration-100 drop-shadow-lg ${
                                    state == 1 ||
                                    state == 2 ||
                                    state == 3 ||
                                    state == 5 ||
                                    state == 10 ||
                                    state == 12 ||
                                    state == 14 ||
                                    state == 16
                                        ? 'cursor-pointer hover:scale-125 '
                                        : 'grayscale opacity-10'
                                }`}
                                onClick={() => {
                                    setError('')
                                    state == 1 ||
                                    state == 2 ||
                                    state == 3 ||
                                    state == 5 ||
                                    state == 10 ||
                                    state == 12 ||
                                    state == 14 ||
                                    state == 16
                                        ? setState(0)
                                        : setState(state)
                                }}
                            />
                        </div>
                        <div className=" w-full flex flex-row justify-center -mt-5 my-3">
                            <div className="text-gray-700 text-2xl font-bold">
                                {info}
                                <span className="text-rose-500 font-bold">
                                    {error}
                                </span>
                            </div>
                        </div>

                        {state == 1 ? (
                            <>
                                <div className="w-full md:w-4/5 rounded-lg drop-shadow-md flex flex-col justify-center py-0 items-center modal-bg1 max-w-[512px] overflow-hidden">
                                    <span className="w-full flex flex-col justify-center items-center ">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="items-centers  text-white/20  place-items-center mx-16 mt-14"
                                                size={120}
                                            />
                                        ) : (
                                            <div className="w-full flex justify-center transition-all duration-500 max-w-[512px] max-h-[512px] overflow-hidden">
                                                <img
                                                    src={nftImg}
                                                    className="w-full h-full scale-110 blur-md absolute rounded-lg transition-all"
                                                />
                                                <img
                                                    src={nftImg}
                                                    className="max-w-full relative max-h-full transition-all"
                                                    style={{
                                                        objectFit: 'contain',
                                                    }}
                                                />
                                            </div>
                                        )}

                                        <span className="w-full absolute flex flex-col hover:-translate-y-48 top-[465px]  tratransition-all duration-500 justify-center pb-10 p-3 bg-black/30 text-gray-200 rounded-b-lg text-md">
                                            <span className="w-full text-center md:text-right text-2xl font-bold -mt-1">
                                                <Rate rate={nftRate} />
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold"></span>

                                                <span className="flex flex-row text-gray-200 ">
                                                    {contractName}
                                                </span>
                                            </div>

                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold"></span>
                                                <span className="flex flex-row text-gray-200">
                                                    ID : {nftID}
                                                </span>
                                            </div>

                                            <span className="w-full text-left font-bold mb-1">
                                                Attributes
                                            </span>
                                            {!!attributes ? (
                                                <div className="bg-gray-400/20 drop-shadow-lg flex flex-col rounded-lg mt-0 p-2 text-left text-white text-sm">
                                                    {attributes.map((x, i) => (
                                                        <div
                                                            key={500 * i}
                                                            className="flex flex-row justify-between"
                                                        >
                                                            <span className="w-1/4">
                                                                {
                                                                    x[1]
                                                                        .trait_type
                                                                }
                                                            </span>
                                                            <span className="w-3/4 overflow-hidden">
                                                                {x[1]
                                                                    .display_type ==
                                                                    'date' &&
                                                                !!nftBirthday
                                                                    ? nftBirthday
                                                                    : x[1]
                                                                          .value}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div />
                                            )}
                                        </span>
                                    </span>
                                </div>
                                <div className=" w-full max-w-[512px] flex flex-row justify-end">
                                    <div
                                        /* onClick={() => {
                                            setError('')
                                            setInfo('Listing NFT for sale')
                                            setIsPublic(true)
                                            setState(2)
                                        }} */
                                        //className="w-1/3 self-end flex flex-row justify-center bg-red-100 text-red-500 hover:bg-red-200 text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer items-center"
                                        className="w-1/3 grayscale opacity-50 self-end flex flex-row justify-center bg-red-100 text-red-500 text-3xl font-bold p-2 m-2 my-4 rounded-xl md:transition-all shadow-lg  items-center"
                                    >
                                        <MdOutlineSell className="-ml-3 mr-2 " />
                                        Sell
                                    </div>
                                    <div
                                        onClick={() => {
                                            setState(10)
                                            setError('')
                                            setInfo('Transfer NFT')
                                            //getBUSDApprove(nftPrice)
                                        }}
                                        className="w-1/3 self-end flex flex-row justify-center bg-yellow-50 text-yellow-500 hover:bg-yellow-200 text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer items-center"
                                        //className="w-1/3 opacity-50 self-end flex flex-row justify-center bg-yellow-50 text-yellow-500  text-3xl font-bold p-2 m-2 my-4 rounded-xl md:transition-all shadow-lg items-center"
                                    >
                                        <BiMailSend className="-ml-3 mr-2" />{' '}
                                        Send
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {state == 2 ? (
                            <>
                                <div className="w-full md:w-4/5 rounded-lg drop-shadow-md flex flex-col justify-center py-7 items-center bg-black/30 ">
                                    <div className="w-11/12 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineSell step={0} />
                                    </div>
                                    <span className="w-full flex flex-row">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>

                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Owner
                                                </span>

                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>

                                            <span className="w-full text-left font-bold mb-1 flex flex-row">
                                                Selling Price
                                                <img
                                                    className="scale-75 mt-1"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        '/img/coins/busd.png'
                                                    }
                                                />
                                            </span>
                                            <input
                                                placeholder="BUSD"
                                                type="number"
                                                pattern="[0-9]*"
                                                min="1"
                                                step="10"
                                                id="_sellingPrice"
                                                keyboardtype="decimal-pad"
                                                autoComplete="off"
                                                className="text-right m-2 w-full active:outline-none rounded-md px-4 outline-non bg-gray-100 text-slate-600 text-3xl font-bold focus:outline-none"
                                            />

                                            <div className="w-full flex flex-row justify-between my-1">
                                                <span className="font-bold">
                                                    Sell To
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    <div
                                                        onClick={() => {
                                                            setIsPublic(true)

                                                            document.getElementById(
                                                                '_sellToAddress'
                                                            ).disabled = true
                                                        }}
                                                        className={`cursor-pointer  rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            isPublic
                                                                ? 'bg-blue-400'
                                                                : 'bg-white/10 text-gray-500'
                                                        }`}
                                                    >
                                                        Public
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            setIsPublic(false)

                                                            document.getElementById(
                                                                '_sellToAddress'
                                                            ).disabled = false
                                                        }}
                                                        className={`cursor-pointer  rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            !isPublic
                                                                ? 'bg-blue-400'
                                                                : 'bg-white/10 text-gray-500'
                                                        }`}
                                                    >
                                                        Private
                                                    </div>
                                                </span>
                                            </div>
                                            <input
                                                placeholder="Address"
                                                type="text"
                                                min="1"
                                                step="10"
                                                id="_sellToAddress"
                                                keyboardtype="decimal-pad"
                                                autoComplete="off"
                                                className={`text-right m-2 w-full active:outline-none md:transition-all rounded-md px-4 outline-non  text-slate-600 text-3xl font-bold focus:outline-none ${
                                                    !isPublic
                                                        ? 'bg-gray-100'
                                                        : 'bg-white/10 text-gray-500 opacity-40'
                                                }`}
                                            />
                                        </span>
                                    </span>
                                    <span className="text-white/70 mt-2 -mb-5 flex flex-row items-center text-sm">
                                        Listing Fee{' '}
                                        <img
                                            className="scale-75 ml-1"
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/img/coins/jbccoin.png'
                                            }
                                        />{' '}
                                        {listingFee} / Selling Fee {sellingFee}%
                                    </span>
                                </div>
                                <div className="w-full md:w-4/5 flex flex-row justify-between">
                                    <div
                                        onClick={() => {
                                            setState(1)
                                            setError('')
                                            setInfo('NFT Detail')
                                        }}
                                        className="w-1/2 self-end bg-black/30 text-gray-500 hover:bg-white/10 text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Back
                                    </div>
                                    <div
                                        onClick={() => {
                                            if (
                                                document.getElementById(
                                                    '_sellingPrice'
                                                ).value > 0
                                            ) {
                                                setSellingPrice(
                                                    document.getElementById(
                                                        '_sellingPrice'
                                                    ).value
                                                )

                                                if (isPublic) {
                                                    // GO NEXT
                                                    setInfo(
                                                        'Click Next to Approve your NFT'
                                                    )
                                                    setError('')
                                                    setSellToAddr(
                                                        '0x0000000000000000000000000000000000000000'
                                                    )
                                                    setState(3)
                                                } else {
                                                    if (
                                                        ethers.utils.isAddress(
                                                            document.getElementById(
                                                                '_sellToAddress'
                                                            ).value
                                                        )
                                                    ) {
                                                        //GO NEXT
                                                        setInfo(
                                                            'Click Next to Approve your NFT'
                                                        )
                                                        setError('')
                                                        setSellToAddr(
                                                            document.getElementById(
                                                                '_sellToAddress'
                                                            ).value
                                                        )
                                                        setState(3)
                                                    } else {
                                                        setInfo('')
                                                        setError(
                                                            'Invalid Sell to Address'
                                                        )
                                                    }
                                                }
                                            } else {
                                                setInfo('')
                                                setError(
                                                    'Please enter Selling Price'
                                                )
                                            }
                                        }}
                                        className="w-1/2 self-end bg-blue-400 text-gray-200 hover:bg-yellow-500  text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Next
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {/* //////////////////////////////////////////////////////////////////////////////////// */}
                        {state == 3 ? (
                            <>
                                <div className="w-full md:w-4/5 rounded-lg drop-shadow-md flex flex-col justify-center py-7 items-center bg-black/30 ">
                                    <div className="w-11/12 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineSell step={1} />
                                    </div>
                                    <span className="w-full flex flex-row">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>

                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Owner
                                                </span>

                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>

                                            <span className="w-full text-left font-bold mb-1 flex flex-row">
                                                Selling Price
                                                <img
                                                    className="scale-75 mt-1"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        '/img/coins/busd.png'
                                                    }
                                                />
                                            </span>
                                            <span className="text-right m-2 w-full active:outline-none rounded-md px-4 outline-non  text-white text-4xl font-bold focus:outline-none">
                                                {separator(sellingPrice)}
                                            </span>

                                            <div className="w-full flex flex-row justify-between my-1">
                                                <span className="font-bold">
                                                    Sell To
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            isPublic
                                                                ? 'bg-blue-400'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Public
                                                    </div>
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            !isPublic
                                                                ? 'bg-blue-400'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Private
                                                    </div>
                                                </span>
                                            </div>
                                            <span
                                                placeholder="Address"
                                                type="text"
                                                min="1"
                                                step="10"
                                                id="_sellToAddress"
                                                keyboardtype="decimal-pad"
                                                autoComplete="off"
                                                className={`text-center m-2 p-2 w-full active:outline-none md:transition-all rounded-md px-4 outline-non  text-md font-bold focus:outline-none ${
                                                    !isPublic
                                                        ? 'bg-gray-100/5'
                                                        : 'bg-white/10 text-white/10 opacity-40'
                                                }`}
                                            >
                                                {sellToAddr.slice(0, 9) +
                                                    '....' +
                                                    sellToAddr.slice(-9)}
                                            </span>
                                        </span>
                                    </span>
                                    <span className="text-white/70 mt-2 -mb-5 flex flex-row items-center text-sm">
                                        Listing Fee{' '}
                                        <img
                                            className="scale-75 ml-1"
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/img/coins/jbccoin.png'
                                            }
                                        />{' '}
                                        {listingFee} / Selling Fee {sellingFee}%
                                    </span>
                                </div>
                                <div className="w-full md:w-4/5 flex flex-row justify-between">
                                    <div
                                        onClick={() => {
                                            setState(2)
                                            setError('')
                                            setInfo('Listing NFT for Sale')
                                        }}
                                        className="w-1/2 self-end bg-black/30 text-gray-500 hover:bg-white/10 text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Back
                                    </div>
                                    <div
                                        onClick={() => {
                                            setState(4)
                                            setError('')
                                            setInfo('Checking NFT Approval')
                                            getApproveNFT(
                                                nftAddress,
                                                nftID,
                                                contractName,
                                                5
                                            )
                                        }}
                                        className="w-1/2 self-end bg-blue-400 text-gray-200 hover:bg-yellow-500  text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Next
                                    </div>
                                </div>
                            </>
                        ) : null}

                        {state == 4 ? (
                            <>
                                <div className="w-full md:w-4/5 rounded-lg drop-shadow-md flex flex-col justify-center py-7 items-center bg-black/30 ">
                                    <div className="w-11/12 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineSell step={3} />
                                    </div>
                                    <span className="w-full flex flex-row opacity-50 grayscale">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>

                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Owner
                                                </span>

                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>

                                            <span className="w-full text-left font-bold mb-1 flex flex-row">
                                                Selling Price
                                                <img
                                                    className="scale-75 mt-1"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        '/img/coins/busd.png'
                                                    }
                                                />
                                            </span>
                                            <span className="text-right m-2 w-full active:outline-none rounded-md px-4 outline-non  text-white text-4xl font-bold focus:outline-none">
                                                {separator(sellingPrice)}
                                            </span>

                                            <div className="w-full flex flex-row justify-between my-1">
                                                <span className="font-bold">
                                                    Sell To
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            isPublic
                                                                ? 'bg-blue-400'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Public
                                                    </div>
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            !isPublic
                                                                ? 'bg-blue-400'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Private
                                                    </div>
                                                </span>
                                            </div>
                                            <span
                                                placeholder="Address"
                                                type="text"
                                                min="1"
                                                step="10"
                                                id="_sellToAddress"
                                                keyboardtype="decimal-pad"
                                                autoComplete="off"
                                                className={`text-center m-2 p-2 w-full active:outline-none md:transition-all rounded-md px-4 outline-non  text-md font-bold focus:outline-none ${
                                                    !isPublic
                                                        ? 'bg-gray-100/5'
                                                        : 'bg-white/10 text-white/10 opacity-40'
                                                }`}
                                            >
                                                {sellToAddr.slice(0, 9) +
                                                    '....' +
                                                    sellToAddr.slice(-9)}
                                            </span>
                                        </span>
                                    </span>
                                    <span className="text-white/70 mt-2 -mb-5 flex flex-row items-center text-sm">
                                        Listing Fee{' '}
                                        <img
                                            className="scale-75 ml-1"
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/img/coins/jbccoin.png'
                                            }
                                        />{' '}
                                        {listingFee} / Selling Fee {sellingFee}%
                                    </span>
                                </div>
                                <div className="w-4/5 flex flex-row justify-between">
                                    <div className="w-1/2 self-end bg-black/10 text-gray-500/30 text-3xl font-bold p-2 m-2 my-4 rounded-xl md:transition-all shadow-lg">
                                        Back
                                    </div>

                                    <div className="relative w-1/2 bg-gray-400/50 text-white p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                                        <AiOutlineLoading3Quarters
                                            color="inherit"
                                            fontSize={24}
                                            className="mx-3 font-bold animate-spin"
                                        />
                                        <span className="animate-pulse text-3xl font-bold">
                                            Waiting
                                        </span>
                                    </div>
                                </div>
                            </>
                        ) : null}

                        {state == 5 ? (
                            <>
                                <div className="w-full md:w-4/5 rounded-lg drop-shadow-md flex flex-col justify-center py-7 items-center bg-black/30 ">
                                    <div className="w-11/12 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineSell step={3} />
                                    </div>
                                    <span className="w-full flex flex-row opacity-50 grayscale">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>

                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Owner
                                                </span>

                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>

                                            <span className="w-full text-left font-bold mb-1 flex flex-row">
                                                Selling Price
                                                <img
                                                    className="scale-75 mt-1"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        '/img/coins/busd.png'
                                                    }
                                                />
                                            </span>
                                            <span className="text-right m-2 w-full active:outline-none rounded-md px-4 outline-non  text-white text-4xl font-bold focus:outline-none">
                                                {separator(sellingPrice)}
                                            </span>

                                            <div className="w-full flex flex-row justify-between my-1">
                                                <span className="font-bold">
                                                    Sell To
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            isPublic
                                                                ? 'bg-blue-400'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Public
                                                    </div>
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            !isPublic
                                                                ? 'bg-blue-400'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Private
                                                    </div>
                                                </span>
                                            </div>
                                            <span
                                                placeholder="Address"
                                                type="text"
                                                min="1"
                                                step="10"
                                                id="_sellToAddress"
                                                keyboardtype="decimal-pad"
                                                autoComplete="off"
                                                className={`text-center m-2 p-2 w-full active:outline-none md:transition-all rounded-md px-4 outline-non  text-md font-bold focus:outline-none ${
                                                    !isPublic
                                                        ? 'bg-gray-100/5'
                                                        : 'bg-white/10 text-white/10 opacity-40'
                                                }`}
                                            >
                                                {sellToAddr.slice(0, 9) +
                                                    '....' +
                                                    sellToAddr.slice(-9)}
                                            </span>
                                        </span>
                                    </span>
                                    <span className="text-white/70 mt-2 -mb-5 flex flex-row items-center text-sm">
                                        Listing Fee{' '}
                                        <img
                                            className="scale-75 ml-1"
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/img/coins/jbccoin.png'
                                            }
                                        />{' '}
                                        {listingFee} / Selling Fee {sellingFee}%
                                    </span>
                                </div>
                                <div className="w-full md:w-4/5 flex flex-row justify-between">
                                    <div
                                        onClick={() => {
                                            setState(2)
                                            setError('')
                                            setInfo('Listing NFT for Sale')
                                        }}
                                        className="w-1/2 self-end bg-black/30 text-gray-500 hover:bg-white/10 text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Back
                                    </div>
                                    <div
                                        onClick={() => {
                                            setError('')
                                            setInfo('Checking PTC Approval')
                                            //getCCOINApprove(50, MARKRT, 7)
                                            setState(6)
                                        }}
                                        className="w-1/2 self-end bg-blue-400 text-gray-200 hover:bg-yellow-500  text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Next
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {state == 6 ? (
                            <>
                                <div className="w-full md:w-4/5 rounded-lg drop-shadow-md flex flex-col justify-center py-7 items-center bg-black/30 ">
                                    <div className="w-11/12 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineSell step={4} />
                                    </div>
                                    <span className="w-full flex flex-row opacity-50 grayscale">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>

                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Owner
                                                </span>

                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>

                                            <span className="w-full text-left font-bold mb-1 flex flex-row">
                                                Selling Price
                                                <img
                                                    className="scale-75 mt-1"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        '/img/coins/busd.png'
                                                    }
                                                />
                                            </span>
                                            <span className="text-right m-2 w-full active:outline-none rounded-md px-4 outline-non  text-white text-4xl font-bold focus:outline-none">
                                                {separator(sellingPrice)}
                                            </span>

                                            <div className="w-full flex flex-row justify-between my-1">
                                                <span className="font-bold">
                                                    Sell To
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            isPublic
                                                                ? 'bg-blue-400'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Public
                                                    </div>
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            !isPublic
                                                                ? 'bg-blue-400'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Private
                                                    </div>
                                                </span>
                                            </div>
                                            <span
                                                placeholder="Address"
                                                type="text"
                                                min="1"
                                                step="10"
                                                id="_sellToAddress"
                                                keyboardtype="decimal-pad"
                                                autoComplete="off"
                                                className={`text-center m-2 p-2 w-full active:outline-none md:transition-all rounded-md px-4 outline-non  text-md font-bold focus:outline-none ${
                                                    !isPublic
                                                        ? 'bg-gray-100/5'
                                                        : 'bg-white/10 text-white/10 opacity-40'
                                                }`}
                                            >
                                                {sellToAddr.slice(0, 9) +
                                                    '....' +
                                                    sellToAddr.slice(-9)}
                                            </span>
                                        </span>
                                    </span>
                                    <span className="text-white/70 mt-2 -mb-5 flex flex-row items-center text-sm">
                                        Listing Fee{' '}
                                        <img
                                            className="scale-75 ml-1"
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/img/coins/jbccoin.png'
                                            }
                                        />{' '}
                                        {listingFee} / Selling Fee {sellingFee}%
                                    </span>
                                </div>
                                <div className="w-full md:w-4/5 flex flex-row justify-between">
                                    <div className="w-1/2 self-end bg-black/10 text-gray-500/30 text-3xl font-bold p-2 m-2 my-4 rounded-xl md:transition-all shadow-lg">
                                        Back
                                    </div>

                                    <div className="relative w-1/2 bg-gray-400/50 text-white p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                                        <AiOutlineLoading3Quarters
                                            color="inherit"
                                            fontSize={24}
                                            className="mx-3 font-bold animate-spin"
                                        />
                                        <span className="animate-pulse text-3xl font-bold">
                                            Waiting
                                        </span>
                                    </div>
                                </div>
                            </>
                        ) : null}

                        {state == 7 ? (
                            <>
                                <div className="w-full md:w-4/5 rounded-lg drop-shadow-md flex flex-col justify-center py-7 items-center bg-black/30 ">
                                    <div className="w-11/12 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineSell step={4} />
                                    </div>
                                    <span className="w-full flex flex-row opacity-50 grayscale">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>

                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Owner
                                                </span>

                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>

                                            <span className="w-full text-left font-bold mb-1 flex flex-row">
                                                Selling Price
                                                <img
                                                    className="scale-75 mt-1"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        '/img/coins/busd.png'
                                                    }
                                                />
                                            </span>
                                            <span className="text-right m-2 w-full active:outline-none rounded-md px-4 outline-non  text-white text-4xl font-bold focus:outline-none">
                                                {separator(sellingPrice)}
                                            </span>

                                            <div className="w-full flex flex-row justify-between my-1">
                                                <span className="font-bold">
                                                    Sell To
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            isPublic
                                                                ? 'bg-blue-400'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Public
                                                    </div>
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            !isPublic
                                                                ? 'bg-blue-400'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Private
                                                    </div>
                                                </span>
                                            </div>
                                            <span
                                                placeholder="Address"
                                                type="text"
                                                min="1"
                                                step="10"
                                                id="_sellToAddress"
                                                keyboardtype="decimal-pad"
                                                autoComplete="off"
                                                className={`text-center m-2 p-2 w-full active:outline-none md:transition-all rounded-md px-4 outline-non  text-md font-bold focus:outline-none ${
                                                    !isPublic
                                                        ? 'bg-gray-100/5'
                                                        : 'bg-white/10 text-white/10 opacity-40'
                                                }`}
                                            >
                                                {sellToAddr.slice(0, 9) +
                                                    '....' +
                                                    sellToAddr.slice(-9)}
                                            </span>
                                        </span>
                                    </span>
                                    <span className="text-white/70 mt-2 -mb-5 flex flex-row items-center text-sm">
                                        Listing Fee{' '}
                                        <img
                                            className="scale-75 ml-1"
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/img/coins/jbccoin.png'
                                            }
                                        />{' '}
                                        {listingFee} / Selling Fee {sellingFee}%
                                    </span>
                                </div>
                                <div className="w-full md:w-4/5 flex flex-row justify-between">
                                    <div
                                        onClick={() => {
                                            setState(2)
                                            setError('')
                                            setInfo('Listing NFT for Sale')
                                        }}
                                        className="w-1/2 self-end bg-black/30 text-gray-500 hover:bg-white/10 text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Back
                                    </div>
                                    <div
                                        onClick={() => {
                                            setError('')
                                            setInfo(
                                                'Please Confirm Metamask to List your NFT'
                                            )

                                            addList(
                                                nftID,
                                                sellingPrice,
                                                nftAddress,
                                                account,
                                                sellToAddr
                                            )
                                            setState(8)
                                        }}
                                        className="w-1/2 self-end bg-blue-400 text-gray-200 hover:bg-yellow-500  text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Next
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {state == 8 ? (
                            <>
                                <div className="w-full md:w-4/5 rounded-lg drop-shadow-md flex flex-col justify-center py-7 items-center bg-black/30 ">
                                    <div className="w-11/12 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineSell step={5} />
                                    </div>
                                    <span className="w-full flex flex-row opacity-50 grayscale">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>

                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Owner
                                                </span>

                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>

                                            <span className="w-full text-left font-bold mb-1 flex flex-row">
                                                Selling Price
                                                <img
                                                    className="scale-75 mt-1"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        '/img/coins/busd.png'
                                                    }
                                                />
                                            </span>
                                            <span className="text-right m-2 w-full active:outline-none rounded-md px-4 outline-non  text-white text-4xl font-bold focus:outline-none">
                                                {separator(sellingPrice)}
                                            </span>

                                            <div className="w-full flex flex-row justify-between my-1">
                                                <span className="font-bold">
                                                    Sell To
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            isPublic
                                                                ? 'bg-blue-400'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Public
                                                    </div>
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            !isPublic
                                                                ? 'bg-blue-400'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Private
                                                    </div>
                                                </span>
                                            </div>
                                            <span
                                                placeholder="Address"
                                                type="text"
                                                min="1"
                                                step="10"
                                                id="_sellToAddress"
                                                keyboardtype="decimal-pad"
                                                autoComplete="off"
                                                className={`text-center m-2 p-2 w-full active:outline-none md:transition-all rounded-md px-4 outline-non  text-md font-bold focus:outline-none ${
                                                    !isPublic
                                                        ? 'bg-gray-100/5'
                                                        : 'bg-white/10 text-white/10 opacity-40'
                                                }`}
                                            >
                                                {sellToAddr.slice(0, 9) +
                                                    '....' +
                                                    sellToAddr.slice(-9)}
                                            </span>
                                        </span>
                                    </span>
                                    <span className="text-white/70 mt-2 -mb-5 flex flex-row items-center text-sm">
                                        Listing Fee{' '}
                                        <img
                                            className="scale-75 ml-1"
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/img/coins/jbccoin.png'
                                            }
                                        />{' '}
                                        {listingFee} / Selling Fee {sellingFee}%
                                    </span>
                                </div>
                                <div className="w-full md:w-4/5 flex flex-row justify-between">
                                    <div className="w-1/2 self-end bg-black/10 text-gray-500/30 text-3xl font-bold p-2 m-2 my-4 rounded-xl md:transition-all shadow-lg">
                                        Back
                                    </div>

                                    <div className="relative w-1/2 bg-gray-400/50 text-white p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                                        <AiOutlineLoading3Quarters
                                            color="inherit"
                                            fontSize={24}
                                            className="mx-3 font-bold animate-spin"
                                        />
                                        <span className="animate-pulse text-3xl font-bold">
                                            Waiting
                                        </span>
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {state == 9 ? (
                            <>
                                <div className="w-full md:w-4/5 rounded-lg drop-shadow-md flex flex-col justify-center py-7 items-center bg-black/30 ">
                                    <div className="w-11/12 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineSell step={6} />
                                    </div>
                                    <span className="w-full flex flex-row ">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>

                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Owner
                                                </span>

                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>

                                            <span className="w-full text-left font-bold mb-1 flex flex-row">
                                                Selling Price
                                                <img
                                                    className="scale-75 mt-1"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        '/img/coins/busd.png'
                                                    }
                                                />
                                            </span>
                                            <span className="text-right m-2 w-full active:outline-none rounded-md px-4 outline-non  text-white text-4xl font-bold focus:outline-none">
                                                {separator(sellingPrice)}
                                            </span>

                                            <div className="w-full flex flex-row justify-between my-1">
                                                <span className="font-bold">
                                                    Sell To
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            isPublic
                                                                ? 'bg-blue-400'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Public
                                                    </div>
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            !isPublic
                                                                ? 'bg-blue-400'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Private
                                                    </div>
                                                </span>
                                            </div>
                                            <span
                                                placeholder="Address"
                                                type="text"
                                                min="1"
                                                step="10"
                                                id="_sellToAddress"
                                                keyboardtype="decimal-pad"
                                                autoComplete="off"
                                                className={`text-center m-2 p-2 w-full active:outline-none md:transition-all rounded-md px-4 outline-non  text-md font-bold focus:outline-none ${
                                                    !isPublic
                                                        ? 'bg-gray-100/5'
                                                        : 'bg-white/10 text-white/10 opacity-40'
                                                }`}
                                            >
                                                {sellToAddr.slice(0, 9) +
                                                    '....' +
                                                    sellToAddr.slice(-9)}
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <div className="w-4/5 flex flex-row justify-between">
                                    <div className="w-1/2 self-end bg-black/10 text-gray-500/30 text-3xl font-bold p-2 m-2 my-4 rounded-xl md:transition-all shadow-lg">
                                        Back
                                    </div>

                                    <div
                                        onClick={() => {
                                            setInfo('')
                                            setError('')
                                            setState(0)
                                        }}
                                        className="w-1/2 self-end bg-blue-400 text-gray-200 hover:bg-yellow-500 text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Done
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {/* /////////////////////////////////////// T R A N S F E R ///////////////////////////////////////////// */}
                        {state == 10 ? (
                            <>
                                <div className="md:w-4/5 w-full rounded-3xl drop-shadow-md flex flex-col justify-center py-1 bg-white ">
                                    <div className="w-11/12 mx-6 flex flex-row my-1 p-3 bg-white/5 rounded-lg">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 items-centers text-white place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 flex justify-center rounded-l-lg transition-all duration-500 overflow-hidden">
                                                <img
                                                    src={nftImg}
                                                    className="w-full object-contain "
                                                    alt="NFT Image"
                                                />
                                            </div>
                                        )}

                                        <span className="w-2/3 flex flex-col justify-center p-5 bg-black/10 text-gray-700 rounded-r-lg text-md whitespace-nowrap">
                                            <span className="w-full text-center md:text-right text-xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold"></span>

                                                <span className="flex flex-row text-gray-700 ">
                                                    {contractName}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row text-gray-700 hover:text-pink-600 justify-between">
                                                <span className="font-bold"></span>
                                                <a
                                                    href={`https://exp-l1.jibchain.net/address/${nftAddress}`}
                                                    target="_blank"
                                                >
                                                    {nftAddress.slice(0, 7) +
                                                        '....' +
                                                        nftAddress.slice(-7)}
                                                </a>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold"></span>
                                                <span className="flex flex-row text-gray-700">
                                                    ID : {nftID}
                                                </span>
                                            </div>
                                        </span>
                                    </div>

                                    <div className="w-11/12 mx-6 flex flex-col my-0 p-3 bg-white/5 rounded-lg ">
                                        <div className="flex flex-row justify-start my-0 font-bold text-pink-600 text-left text-2xl">
                                            To
                                        </div>
                                        <input
                                            placeholder="Address"
                                            type="text"
                                            min="1"
                                            step="10"
                                            id="_sendToAddress"
                                            keyboardtype="decimal-pad"
                                            autoComplete="off"
                                            className="text-center w-full bg-pink-500/10 active:outline-none md:transition-all rounded-md px-4 py-2 outline-non  text-slate-600 text-xl font-bold focus:outline-none"
                                        />
                                    </div>
                                </div>

                                <div className="w-full md:w-4/5 flex flex-row justify-between">
                                    <div
                                        onClick={() => {
                                            setState(1)
                                            setError('')
                                            setInfo('NFT Detail')
                                        }}
                                        className="w-1/2 self-end  text-gray-500 bg-white/10 hover:bg-black/20 text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Back
                                    </div>
                                    <div
                                        onClick={() => {
                                            if (
                                                ethers.utils.isAddress(
                                                    document.getElementById(
                                                        '_sendToAddress'
                                                    ).value
                                                )
                                            ) {
                                                //GO NEXT
                                                setInfo(
                                                    'Confirm metamask to transfer your NFT'
                                                )
                                                setError('')
                                                setSellToAddr(
                                                    document.getElementById(
                                                        '_sendToAddress'
                                                    ).value
                                                )
                                                setState(11)
                                                safeTransferFrom(
                                                    nftAddress,
                                                    account,
                                                    document.getElementById(
                                                        '_sendToAddress'
                                                    ).value,
                                                    nftID
                                                )
                                            } else {
                                                setInfo('')
                                                setError('Invalid Address')
                                            }
                                        }}
                                        className="w-1/2 self-end bg-blue-400 text-gray-200 hover:bg-red-500 text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Next
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {/* //////////////////////////////////////////////////////////////////////////////////// */}

                        {state == 11 ? (
                            <>
                                <div className="md:w-4/5 w-full rounded-3xl drop-shadow-md flex flex-col justify-center py-1 bg-white ">
                                    <div className="w-11/12 mx-6 flex flex-col my-1 p-3 bg-white/5 rounded-lg ">
                                        <div className="flex flex-row justify-start my-1 font-bold text-pink-600 text-left text-2xl">
                                            From
                                        </div>
                                        <div className="text-center w-full bg-pink-500/10 md:transition-all rounded-md px-4 py-2 outline-non text-black text-xl font-bold overflow-hidden whitespace-nowrap text-overflow-ellipsis">
                                            {account.slice(0, 9) +
                                                '....' +
                                                account.slice(-9)}
                                        </div>
                                    </div>
                                    <div className="w-11/12 mx-6 flex flex-row my-1 p-3 bg-white/5 rounded-lg">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 items-centers text-white place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 flex justify-center rounded-l-lg transition-all duration-500 overflow-hidden">
                                                <img
                                                    src={nftImg}
                                                    className="w-full object-contain "
                                                    alt="NFT Image"
                                                />
                                            </div>
                                        )}

                                        <span className="w-2/3 flex flex-col justify-center p-5 bg-black/10 text-gray-700 rounded-r-lg text-md whitespace-nowrap">
                                            <span className="w-full text-center md:text-right text-xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold"></span>

                                                <span className="flex flex-row text-gray-700 ">
                                                    {contractName}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row text-gray-700 hover:text-pink-600 justify-between">
                                                <span className="font-bold"></span>
                                                <a
                                                    href={`https://exp-l1.jibchain.net/address/${nftAddress}`}
                                                    target="_blank"
                                                >
                                                    {nftAddress.slice(0, 7) +
                                                        '....' +
                                                        nftAddress.slice(-7)}
                                                </a>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold"></span>
                                                <span className="flex flex-row text-gray-700">
                                                    ID : {nftID}
                                                </span>
                                            </div>
                                        </span>
                                    </div>

                                    <div className="w-11/12 mx-6 flex flex-col my-0 p-3 bg-white/5 rounded-lg ">
                                        <div className="flex flex-row justify-start my-0 font-bold text-pink-600 text-left text-2xl">
                                            To
                                        </div>
                                        <div className="text-center w-full bg-pink-500/10 md:transition-all rounded-md px-4 py-2 outline-non text-black text-xl font-bold overflow-hidden whitespace-nowrap text-overflow-ellipsis">
                                            {sellToAddr.slice(0, 9) +
                                                '....' +
                                                sellToAddr.slice(-9)}
                                        </div>
                                        <div className="flex opacity-0 flex-row justify-center my-1 font-bold text-gray-700 hover:text-gray-400 text-left text-sm">
                                            View transaction
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full md:w-4/5 flex flex-row justify-end">
                                    <div className="w-4/5 flex flex-row justify-between">
                                        <div className="w-1/2 self-end bg-black/10 text-gray-500/30 text-3xl font-bold p-2 m-2 my-4 rounded-xl md:transition-all shadow-lg">
                                            Back
                                        </div>

                                        <div className="relative w-1/2 bg-gray-400/50 text-white p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                                            <AiOutlineLoading3Quarters
                                                color="inherit"
                                                fontSize={24}
                                                className="mx-3 font-bold animate-spin"
                                            />
                                            <span className="animate-pulse text-3xl font-bold">
                                                Waiting
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {/* //////////////////////////////////////////////////////////////////////////////////// */}
                        {state == 12 ? (
                            <>
                                <div className="md:w-4/5 w-full rounded-3xl drop-shadow-md flex flex-col justify-center py-7 bg-black/30 ">
                                    <div className="w-11/12 mx-6 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineSell
                                            step={2}
                                            mode={'Transfer'}
                                        />
                                    </div>
                                    <span className="w-full flex flex-row scale-90">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 h-min -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Amount
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftAmount}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Owner
                                                </span>

                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Token Standard
                                                </span>

                                                <span className="flex flex-row text-gray-200 ">
                                                    {contractName}
                                                </span>
                                            </div>
                                        </span>
                                    </span>
                                    <div className="w-11/12 mx-6 flex flex-col my-1 p-3 bg-white/5 rounded-lg ">
                                        <div className="flex flex-row justify-start my-1 font-bold text-gray-100 text-left text-2xl">
                                            Send To
                                        </div>
                                        <div className="text-center w-full bg-black/30 md:transition-all rounded-md px-4 py-2 outline-non  text-slate-300 text-xl font-bold">
                                            {sellToAddr}
                                        </div>
                                        <span className="text-white/70 mt-1 -mb-2 flex flex-row items-center justify-center text-sm">
                                            Transfer Fee
                                            <img
                                                className="scale-75 ml-1"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/img/coins/jbccoin.png'
                                                }
                                            />
                                            {transferFee}
                                        </span>
                                    </div>
                                </div>

                                <div className="w-full md:w-4/5 flex flex-row justify-between">
                                    <div
                                        onClick={() => {
                                            setState(1)
                                            setError('')
                                            setInfo('NFT Detail')
                                        }}
                                        className="w-1/2 self-end bg-black/30 text-gray-500 hover:bg-white/10 text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Back
                                    </div>
                                    <div
                                        onClick={() => {
                                            setState(13)
                                            setInfo(
                                                'Confirm Metamask to Approve your PTC'
                                            )
                                            //getCCOINApprove(50, MARKRT, 14)
                                        }}
                                        className="w-1/2 self-end bg-blue-400 text-gray-200 hover:bg-yellow-500  text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Next
                                    </div>
                                </div>
                            </>
                        ) : null}

                        {/* //////////////////////////////////////////////////////////////////////////////////// */}
                        {state == 13 ? (
                            <>
                                <div className="md:w-4/5 w-full rounded-3xl drop-shadow-md flex flex-col justify-center py-7 bg-black/30 ">
                                    <div className="w-11/12 mx-6 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineSell
                                            step={3}
                                            mode={'Transfer'}
                                        />
                                    </div>
                                    <span className="w-full flex flex-row scale-90">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 h-min -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Amount
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftAmount}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Owner
                                                </span>

                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Token Standard
                                                </span>

                                                <span className="flex flex-row text-gray-200 ">
                                                    {contractName}
                                                </span>
                                            </div>
                                        </span>
                                    </span>
                                    <div className="w-11/12 mx-6 flex flex-col my-1 p-3 bg-white/5 rounded-lg ">
                                        <div className="flex flex-row justify-start my-1 font-bold text-gray-100 text-left text-2xl">
                                            Send To
                                        </div>
                                        <div className="text-center w-full bg-black/30 md:transition-all rounded-md px-4 py-2 outline-non  text-slate-300 text-xl font-bold">
                                            {sellToAddr}
                                        </div>
                                        <span className="text-white/70 mt-1 -mb-2 flex flex-row items-center justify-center text-sm">
                                            Transfer Fee
                                            <img
                                                className="scale-75 ml-1"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/img/coins/jbccoin.png'
                                                }
                                            />
                                            {transferFee}
                                        </span>
                                    </div>
                                </div>
                                <div className="w-full md:w-4/5 flex flex-row justify-end">
                                    <div className="w-4/5 flex flex-row justify-between">
                                        <div className="w-1/2 self-end bg-black/10 text-gray-500/30 text-3xl font-bold p-2 m-2 my-4 rounded-xl md:transition-all shadow-lg">
                                            Back
                                        </div>

                                        <div className="relative w-1/2 bg-gray-400/50 text-white p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                                            <AiOutlineLoading3Quarters
                                                color="inherit"
                                                fontSize={24}
                                                className="mx-3 font-bold animate-spin"
                                            />
                                            <span className="animate-pulse text-3xl font-bold">
                                                Waiting
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {/* //////////////////////////////////////////////////////////////////////////////////// */}
                        {state == 14 ? (
                            <>
                                <div className="md:w-4/5 w-full rounded-3xl drop-shadow-md flex flex-col justify-center py-7 bg-black/30 ">
                                    <div className="w-11/12 mx-6 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineSell
                                            step={4}
                                            mode={'Transfer'}
                                        />
                                    </div>
                                    <span className="w-full flex flex-row scale-90">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 h-min -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Amount
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftAmount}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Owner
                                                </span>

                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Token Standard
                                                </span>

                                                <span className="flex flex-row text-gray-200 ">
                                                    {contractName}
                                                </span>
                                            </div>
                                        </span>
                                    </span>
                                    <div className="w-11/12 mx-6 flex flex-col my-1 p-3 bg-white/5 rounded-lg ">
                                        <div className="flex flex-row justify-start my-1 font-bold text-gray-100 text-left text-2xl">
                                            Send To
                                        </div>
                                        <div className="text-center w-full bg-black/30 md:transition-all rounded-md px-4 py-2 outline-non  text-slate-300 text-xl font-bold">
                                            {sellToAddr}
                                        </div>
                                        <span className="text-white/70 mt-1 -mb-2 flex flex-row items-center justify-center text-sm">
                                            Transfer Fee
                                            <img
                                                className="scale-75 ml-1"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/img/coins/jbccoin.png'
                                                }
                                            />
                                            {transferFee}
                                        </span>
                                    </div>
                                </div>

                                <div className="w-full md:w-4/5 flex flex-row justify-between">
                                    <div
                                        onClick={() => {
                                            setState(1)
                                            setError('')
                                            setInfo('NFT Detail')
                                        }}
                                        className="w-1/2 self-end bg-black/30 text-gray-500 hover:bg-white/10 text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Back
                                    </div>
                                    <div
                                        onClick={() => {
                                            setState(15)
                                            setInfo(
                                                'Confirm Metamask to Send your NFT'
                                            )
                                            safeTransferFrom(
                                                nftAddress,
                                                sellToAddr,
                                                nftID
                                            )
                                        }}
                                        className="w-1/2 self-end bg-blue-400 text-gray-200 hover:bg-yellow-500  text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Send
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {/* //////////////////////////////////////////////////////////////////////////////////// */}

                        {state == 15 ? (
                            <>
                                <div className="md:w-4/5 w-full rounded-3xl drop-shadow-md flex flex-col justify-center py-7 bg-black/30 ">
                                    <div className="w-11/12 mx-6 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineSell
                                            step={5}
                                            mode={'Transfer'}
                                        />
                                    </div>
                                    <span className="w-full flex flex-row scale-90">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 h-min -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Amount
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftAmount}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Owner
                                                </span>

                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Token Standard
                                                </span>

                                                <span className="flex flex-row text-gray-200 ">
                                                    {contractName}
                                                </span>
                                            </div>
                                        </span>
                                    </span>
                                    <div className="w-11/12 mx-6 flex flex-col my-1 p-3 bg-white/5 rounded-lg ">
                                        <div className="flex flex-row justify-start my-1 font-bold text-gray-100 text-left text-2xl">
                                            Send To
                                        </div>
                                        <div className="text-center w-full bg-black/30 md:transition-all rounded-md px-4 py-2 outline-non  text-slate-300 text-xl font-bold">
                                            {sellToAddr}
                                        </div>
                                        <span className="text-white/70 mt-1 -mb-2 flex flex-row items-center justify-center text-sm">
                                            Transfer Fee
                                            <img
                                                className="scale-75 ml-1"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/img/coins/jbccoin.png'
                                                }
                                            />
                                            {transferFee}
                                        </span>
                                    </div>
                                </div>
                                <div className="w-full md:w-4/5 flex flex-row justify-end">
                                    <div className="w-4/5 flex flex-row justify-between">
                                        <div className="w-1/2 self-end bg-black/10 text-gray-500/30 text-3xl font-bold p-2 m-2 my-4 rounded-xl md:transition-all shadow-lg">
                                            Back
                                        </div>

                                        <div className="relative w-1/2 bg-gray-400/50 text-white p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                                            <AiOutlineLoading3Quarters
                                                color="inherit"
                                                fontSize={24}
                                                className="mx-3 font-bold animate-spin"
                                            />
                                            <span className="animate-pulse text-3xl font-bold">
                                                Waiting
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {/* //////////////////////////////////////////////////////////////////////////////////// */}
                        {state == 16 ? (
                            <>
                                <div className="md:w-4/5 w-full rounded-3xl drop-shadow-md flex flex-col justify-center py-1 bg-white ">
                                    <div className="w-11/12 mx-6 flex flex-col my-1 p-3 bg-white/5 rounded-lg ">
                                        <div className="flex flex-row justify-start my-1 font-bold text-pink-600 text-left text-2xl">
                                            From
                                        </div>
                                        <div className="text-center w-full bg-pink-500/10 md:transition-all rounded-md px-4 py-2 outline-non text-black text-xl font-bold overflow-hidden whitespace-nowrap text-overflow-ellipsis">
                                            {account.slice(0, 9) +
                                                '....' +
                                                account.slice(-9)}
                                        </div>
                                    </div>
                                    <div className="w-11/12 mx-6 flex flex-row my-1 p-3 bg-white/5 rounded-lg">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 items-centers text-white place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 flex justify-center rounded-l-lg transition-all duration-500 overflow-hidden">
                                                <img
                                                    src={nftImg}
                                                    className="w-full object-contain "
                                                    alt="NFT Image"
                                                />
                                            </div>
                                        )}

                                        <span className="w-2/3 flex flex-col justify-center p-5 bg-black/10 text-gray-700 rounded-r-lg text-md whitespace-nowrap">
                                            <span className="w-full text-center md:text-right text-xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold"></span>

                                                <span className="flex flex-row text-gray-700 ">
                                                    {contractName}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row text-gray-700 hover:text-pink-600 justify-between">
                                                <span className="font-bold"></span>
                                                <a
                                                    href={`https://exp-l1.jibchain.net/address/${nftAddress}`}
                                                    target="_blank"
                                                >
                                                    {nftAddress.slice(0, 7) +
                                                        '....' +
                                                        nftAddress.slice(-7)}
                                                </a>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold"></span>
                                                <span className="flex flex-row text-gray-700">
                                                    ID : {nftID}
                                                </span>
                                            </div>
                                        </span>
                                    </div>

                                    <div className="w-11/12 mx-6 flex flex-col my-0 p-3 bg-white/5 rounded-lg ">
                                        <div className="flex flex-row justify-start my-0 font-bold text-pink-600 text-left text-2xl">
                                            To
                                        </div>
                                        <div className="text-center w-full bg-pink-500/10 md:transition-all rounded-md px-4 py-2 outline-non text-black text-xl font-bold overflow-hidden whitespace-nowrap text-overflow-ellipsis">
                                            {sellToAddr.slice(0, 9) +
                                                '....' +
                                                sellToAddr.slice(-9)}
                                        </div>
                                        <div className="flex flex-row justify-center my-1 font-bold text-gray-700 hover:text-gray-400 text-left text-sm">
                                            <a
                                                href={`https://exp-l1.jibchain.net/tx/${txHash}`}
                                                target="_blank"
                                            >
                                                View transaction
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full md:w-4/5 flex flex-row justify-between">
                                    <div className="w-1/2 self-end bg-black/10 text-gray-500/30 text-3xl font-bold p-2 m-2 my-4 rounded-xl md:transition-all shadow-lg">
                                        Back
                                    </div>

                                    <div
                                        onClick={() => {
                                            setInfo('')
                                            setError('')
                                            setState(0)
                                        }}
                                        className="w-1/2 self-end bg-blue-400 text-gray-200 hover:bg-red-500 text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Done
                                    </div>
                                </div>
                            </>
                        ) : null}

                        <br />
                    </div>
                    <br />
                </Backdrop>

                {/*///////////////////// THE PRIZE //////////////////////////// */}
            </div>
        </>
    )
}

export default MyWallet
