import React from 'react'
import { Link } from 'react-router-dom'

function Home() {
    return (
        <div className="flex flex-col  w-full justifyitem-center items-center mt-20 select-none">
            <Link
                to="/Mirrorme"
                className="bg-gradient-to-t overflow-hidden from-purple-900/50 to-pink-500/40 md:w-2/3 max-w-[512px] mt-3 w-full flex flex-row justifyitem-center items-center drop-shadow-lg rounded-xl border-[#4b4314] bg-neutral-100 bg-opacity-50 backdrop-blur-md"
            >
                <img
                    className="opacity-30 transition-all hover:opacity-90 duration-700"
                    src={process.env.PUBLIC_URL + '/img/mrmmini.png'}
                />
                <div className="absolute w-full top-0 rounded-xl bg-black/0 drop-shadow-md flex flex-col justify-center items-center">
                    <div className="w-full bg-pink-800/30 mt-6 p-3 flex justify-center drop-shadow-md">
                        <img
                            width={200}
                            src={
                                process.env.PUBLIC_URL + '/img/MirrorMeLogo.png'
                            }
                        />
                    </div>
                    <span className="flex flex-row justify-center items-center text-xs p-5 text-zinc-200 text-justify">
                        A girl taking her photo in front of a mirror, showcasing
                        a blend of style, personality, and the intimate moments
                        of self-reflection that a simple mirror selfie can
                        represent.
                    </span>
                    <span className="flex flex-row justify-center items-center font-bold text-2xl text-white hover:text-zinc-400 hover:scale-110 transition-all">
                        Mint Her Now!
                    </span>
                </div>
            </Link>
            <br />
            <Link
                to="/Herup"
                className="bg-gradient-to-t overflow-hidden from-purple-500 to-pink-400 md:w-2/3 max-w-[512px] mt-3 w-full flex flex-row justifyitem-center items-center drop-shadow-lg rounded-xl border-[#4b4314] bg-neutral-100 bg-opacity-50 backdrop-blur-md"
            >
                <img
                    className="opacity-30 transition-all hover:opacity-90 duration-700"
                    src={process.env.PUBLIC_URL + '/img/herupcover.png'}
                />
                <div className="absolute w-full top-0 rounded-xl bg-white/0 drop-shadow-md flex flex-col justify-center items-center">
                    <div className="w-full bg-white/30 mt-5 p-3 flex justify-center drop-shadow-md">
                        <img
                            width={200}
                            src={process.env.PUBLIC_URL + '/img/heruplogo.png'}
                        />
                    </div>
                    <span className="flex flex-row justify-center items-center mt-2 text-xs p-5 text-pink-100 text-justify">
                        Explore our exclusive NFT collection of 99 pieces,
                        reserved for PTC token holders. Featuring 2 Ultra
                        Exclusive and 1 Elite Exclusive items, each piece
                        embodies rarity and sophistication. Secure your token of
                        digital luxury and join an elite community.
                    </span>{' '}
                    <span className="flex flex-row justify-center items-center font-bold text-2xl text-white hover:text-pink-200 hover:scale-110 transition-all">
                        Get it Now!
                    </span>
                </div>
            </Link>
            <br />
            <Link
                to="/Sodsai"
                className=" overflow-hidden md:w-2/3 max-w-[512px] mt-3 w-full flex flex-row justifyitem-center items-center drop-shadow-lg rounded-xl bg-neutral-100 "
            >
                <div className=" w-full flex flex-col justifyitem-center items-center drop-shadow-lg rounded-md bg-neutral-100">
                    <img
                        className="absolute opacity-20 hover:opacity-40  transition-all -z-10 duration-500"
                        src={process.env.PUBLIC_URL + '/img/girlbar.png'}
                    />
                    <div className="text-4xl mt-5">
                        SODS<span className="text-red-500">AI</span>
                    </div>
                    <div>Collection#1</div>

                    <br />

                    <span className="flex flex-row mb-5 justify-center items-center font-bold text-2xl text-red-500 hover:text-black hover:scale-110 transition-all z-20">
                        Mint Now!
                    </span>
                </div>
            </Link>
        </div>
    )
}

export default Home
