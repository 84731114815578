import {
    Navbar,
    Home,
    Sodsai,
    Herup,
    Mirrorme,
    MyWallet,
    Marketplace,
    ViewWallet,
    Footer,
} from './components'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

function App() {
    return (
        <Router>
            <div className="App h-max p-0 md:p-2 relative">
                <Navbar />

                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/Sodsai" element={<Sodsai />} />
                    <Route path="/Herup" element={<Herup />} />
                    <Route path="/Mirrorme" element={<Mirrorme />} />
                    <Route path="/Marketplace" element={<Marketplace />} />
                    <Route path="/MyWallet" element={<MyWallet />} />
                    <Route
                        path="/address/:walletAddress"
                        element={<ViewWallet />}
                    />
                </Routes>
                <Footer />
            </div>
        </Router>
    )
}

export default App
