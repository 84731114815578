import React from 'react'
import { FaStar } from 'react-icons/fa'
function Rate({ rate = 'none' }) {
    let starCount = 0
    switch (rate) {
        case 'Premium':
            starCount = 1
            break
        case 'Special':
            starCount = 2
            break
        case 'Exclusive':
            starCount = 3
            break
        case 'Ultra Exclusive':
            starCount = 4
            break
        case 'Elite Exclusive':
            starCount = 5
            break
        default:
            starCount = 0
    }
    const stars = Array.from({ length: starCount }, (_, index) => (
        <FaStar key={index} />
    ))

    return (
        <div className="w-full absolute bg-black/0 text-[#ffda36] drop-shadow-md rounded-md flex flex-row justify-between p-2 z-30">
            <div className="flex flex-row">{stars}</div>
            <div className="text-xs font-bold"></div>
        </div>
    )
}

export default Rate
