import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ethers } from 'ethers'
import { useSelector } from 'react-redux'
import AnimatedNumber from 'animated-number-react'
import Backdrop from '@mui/material/Backdrop'
import Tooltip from '@mui/material/Tooltip'
import { AiOutlineLoading3Quarters, AiOutlineCopy } from 'react-icons/ai'
import { BiImage } from 'react-icons/bi'
import { BsShop } from 'react-icons/bs'
import { GoSync } from 'react-icons/go'
import {
    MdVerified,
    MdOutlineAccountBalanceWallet,
    MdOutlineGames,
} from 'react-icons/md'
import { FaWindowClose } from 'react-icons/fa'
import SteplineInfuse from './SteplineInfuse'
import AddNetwork from './AddNetwork'
import axios from 'axios'
import IERC1155 from '../abi/IERC1155'
/* Verify Contract */
let verifyContract = []
verifyContract.push(process.env.REACT_APP_NFT1155STAKE_CONTRACT.toLowerCase())
verifyContract.push(process.env.REACT_APP_EGGHATCH_CONTRACT.toLowerCase())

const CCOIN = process.env.REACT_APP_CCOIN
const CCSX = process.env.REACT_APP_CCSX
const BUSD = process.env.REACT_APP_BUSD

const chainID = process.env.REACT_APP_CHAIN_ID
const nftPool = process.env.REACT_APP_NFTPOOL

const ABIPOOL = require('../abi/ABIPOOL')
const IERC20 = require('../abi/IERC20')

//const NFTDATA = require('./nftdata.js')
const formatValue = (value) => `${separator(Number(value).toFixed(0))}`
function separator(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const isMetaMaskInstalled = () => {
    const { ethereum } = window
    return Boolean(ethereum && ethereum.isMetaMask)
}

function PoolList() {
    const [error, setError] = useState()
    const [info, setInfo] = useState()
    const [account, setAccount] = useState()
    const [state, setState] = useState(0)
    const [openBackdrop, setOpenBackdrop] = useState(false)
    const [CCOINBalance, setCCOINBalance] = useState(0)
    const [nfts, setNfts] = useState({})

    const [nftImg, setNftImg] = useState()
    const [nftID, setNftID] = useState()
    const [nftName, setNftName] = useState()
    const [nftOwner, setNftOwner] = useState()
    const [nftAddress, setNftAddress] = useState()
    const [contractType, setContractType] = useState()
    const [attributes, setAttributes] = useState()
    const [listID, setListID] = useState()
    const [nftAmount, setNftAmount] = useState()
    const [reLoad, setReload] = useState(true)

    const wc = useSelector((state) => state.walletConnect.value)

    let provider
    let ethereum = window.ethereum
    let tempSigner

    if (!!wc.account) {
        ethereum = wc.provider
        provider = wc.web3Provider
        tempSigner = provider.getSigner()
        ethereum.on('accountsChanged', (accounts) => setState(0))
    } else if (isMetaMaskInstalled()) {
        ethereum = window.ethereum
        provider = new ethers.providers.Web3Provider(ethereum)
        tempSigner = provider.getSigner()
        ethereum.on('accountsChanged', (accounts) => setState(0))
    }

    useEffect(() => {
        console.clear()
        if (!!wc.account || isMetaMaskInstalled()) {
            getCurrentAccount()
            getCCOINBalance()
        }

        return () => {
            provider = null
            tempSigner = null
        }
    }, [wc.account, account, state])

    useEffect(() => {
        getNFTsByWallet(account, verifyContract)
        return () => {}
    }, [account, reLoad])

    //console.log('tempSigner:', tempSigner)
    //// GET Require error from contract using catch promist ////
    async function reason(_hash) {
        let hash = _hash
        let tx = await provider.getTransaction(hash)
        if (!tx) {
            console.log('Reason : tx not found')
        } else {
            let code = await provider.call(tx, tx.blockNumber)
            return ethers.utils.toUtf8String(code.substr(138))
        }
    }

    // const contract = new ethers.Contract(MARKRT, ABIPOOL, tempSigner)
    //console.log('NTTTrunk:', NTTTrunk)

    async function getCurrentAccount() {
        const accounts = await ethereum.request({
            method: 'eth_accounts',
        })
        setAccount(accounts[0])
        return accounts[0]
    }

    //console.log('list:', list)

    /*async function getBUSDBalance() {
        try {
            const constract = new ethers.Contract(BUSD, IERC20, tempSigner)
            const balance = await constract.callStatic.balanceOf(
                tempSigner.getAddress()
            )
            setBUSDBalance(parseInt(ethers.utils.formatEther(balance)))
        } catch (err) {
            console.log('err: ', err)
        }
    } */

    async function getCCOINBalance() {
        try {
            const constract = new ethers.Contract(CCOIN, IERC20, tempSigner)
            const balance = await constract.callStatic.balanceOf(
                tempSigner.getAddress()
            )
            setCCOINBalance(parseInt(ethers.utils.formatEther(balance)))
        } catch (err) {
            console.log('err: ', err)
        }
    }

    async function getNFTsByWallet(_address, _contracts) {
        console.log('account:', _address)
        console.log('verifyContract:', _contracts)
        try {
            const options = {
                method: 'GET',
                url: 'https://deep-index.moralis.io/api/v2/' + nftPool + '/nft',
                params: {
                    chain: chainID, // testnet : 0x61, mainnet 0x38
                    format: 'decimal',
                    token_addresses: _contracts,
                    normalizeMetadata: 'true',
                },
                headers: {
                    accept: 'application/json',
                    'X-API-Key':
                        'dJNQrl7A3X1aQ2vruDBqKNkrHSUKLUggQyGn8ZaNlf7OiORkdn5smlIh7awApXfq',
                },
            }
            //2hr05pidx8lH2wSpcXDAKZrKE2pVI98urjqVt4hZ6lpnTkWBtv5sjtDrwXYt8dWu
            //dJNQrl7A3X1aQ2vruDBqKNkrHSUKLUggQyGn8ZaNlf7OiORkdn5smlIh7awApXfq
            axios
                .request(options)
                .then(function (response) {
                    //console.log(response.data)
                    /*window.localStorage.setItem(
                        _address.toLowerCase(),
                        JSON.stringify(response.data)
                    )*/

                    // Those 2 line below make obj arrang by token_id
                    const nftArr = response.data.result
                    nftArr.sort((a, b) => a.token_id - b.token_id)
                    console.log('nftArr:', nftArr)

                    setNfts(response.data)
                    //setNfts(response.data)
                })
                .catch(function (error) {
                    console.error(error)
                })

            /*window.localStorage.setItem(
                _address.toLowerCase(),
                JSON.stringify(NFTDATA)
            ) */
        } catch (err) {
            console.log('err: ', err)
        }
    }

    async function getApproveNFT(
        _nftAddress,
        _nftID,
        _contractType,
        _nextStep
    ) {
        const contract = new ethers.Contract(_nftAddress, IERC1155, tempSigner)
        try {
            const check = await contract.callStatic.isApprovedForAll(
                account,
                nftPool
            )
            console.log('check:', check)

            if (!check) {
                const tx = await contract.setApprovalForAll(nftPool, true)
                setInfo('Waiting for block confirmation')
                const receipt = await tx.wait(1).then((tx) => {
                    setState(_nextStep)
                    setInfo('Click Next Infuse your NFT')
                })
            } else {
                setState(_nextStep)
                setInfo('Click Next Infuse your NFT')
            }
        } catch (err) {
            console.log('err: ', err)
            setState(0)
        }
    }

    async function getCCOINApprove(_amount, _marketAddress, _nextStep) {
        try {
            const constract = new ethers.Contract(CCOIN, IERC20, tempSigner)

            if (CCOINBalance >= parseInt(_amount)) {
                const checkAllowance = await constract.callStatic.allowance(
                    account,
                    _marketAddress
                )

                if (
                    parseInt(_amount) >
                    parseInt(ethers.utils.formatEther(checkAllowance))
                ) {
                    setInfo('Please Approve Your CCOIN')
                    console.log('CCOINBalance:', CCOINBalance)
                    const tx = await constract.approve(
                        _marketAddress,
                        ethers.utils.parseEther(CCOINBalance.toString())
                    )
                    setInfo('Waiting for block confirmation')
                    const receipt = await tx.wait(1).then((tx) => {
                        setState(_nextStep)
                        setInfo('List you NFT to Market')
                    })
                } else {
                    setState(_nextStep)
                    setInfo('List you NFT to Market')
                }
            } else {
                setInfo('')
                setError('Insufficient CCOIN Balance.')
                setState(5)
            }
        } catch (err) {
            console.log('err: ', err)
            setState(0)
        }
    }
    async function depositNFT(_nftAddr, _ids, _tokenAmt) {
        const contract = new ethers.Contract(nftPool, ABIPOOL, tempSigner)
        try {
            const tx = await contract.depositNFT(_nftAddr, _ids, _tokenAmt)
            setInfo('Waiting for block confirmation')
            const receipt = await tx.wait(1).then((tx) => {
                setState(16)
                setInfo('Transaction Complete')
            })
        } catch (err) {
            setState(16)
            setInfo('Transaction Complete')
            console.log('err: ', err)
        }
    }

    async function addList(
        _nftID,
        _sellingPrice,
        _nftAddr,
        _sellerAddr,
        _buyerAddr
    ) {
        const contract = new ethers.Contract(nftPool, ABIPOOL, tempSigner)

        try {
            const tx = await contract.AddList(
                parseInt(_nftID),
                ethers.utils.parseEther(_sellingPrice.toString()),
                _nftAddr,
                _sellerAddr,
                _buyerAddr
            )
            setInfo('Waiting for block confirmation')
            const receipt = await tx.wait(1).then((tx) => {
                setState(9)
                setInfo('Transaction Complete')
            })
        } catch (err) {
            setState(0)
            console.log('err: ', err)
        }
    }

    /*//////////////////////////////////////////////////////////////////*/
    function Lists({
        _nftId,
        nftAddr,
        nftName,
        amount,
        image,
        nftOwner,
        attributes,
        contractType,
    }) {
        if (
            (typeof attributes === 'object' ||
                typeof attributes === 'function') &&
            attributes !== null
        ) {
            attributes = Object.entries(attributes)

            //console.log('attributes:', attributes)
        }
        function nftDetails() {
            setInfo('NFT Detail')
            setNftImg(image)
            setNftID(_nftId)
            setNftName(nftName)
            setNftAmount(amount)
            setNftOwner(nftOwner)
            setNftAddress(nftAddr)
            setAttributes(attributes)
            setContractType(contractType)
            setState(1)
            setOpenBackdrop(true)
            setListID(listID)
        }

        return (
            <div className="col-span-1 md:transition-all duration-500 m-1 drop-shadow-xl">
                <div
                    onClick={() => {
                        nftDetails()
                    }}
                    className="bg-white hover:bg-orange-200 cursor-pointer  grid grid-flow-row grid-cols-4 overflow-hidden items-center rounded-md"
                >
                    <span className="col-span-4 text-gray-600 text-sm font-bold flex justify-center items-center bg-black/10">
                        {nftName == null ? 'Undefined' : nftName}
                    </span>
                    <div className="col-span-1 overflow-hidden   flex justify-center">
                        {image == null ? (
                            <BiImage
                                className="items-centers text-white/20 place-items-center"
                                size={96}
                            />
                        ) : (
                            <img
                                className="px-1 md:transition-all duration-1000 drop-shadow-xl"
                                src={`https://nft.crypterium.game/img/pix/${_nftId}.png`}
                            />
                        )}
                    </div>

                    <div className="col-span-2 flex flex-col justify-start  p-1">
                        <span className="text-xs text-gray-500">
                            ID : {_nftId}
                        </span>

                        <span className="text-xs text-gray-500">
                            {nftAddr.slice(0, 5) + '....' + nftAddr.slice(-4)}
                        </span>
                    </div>

                    <span className="col-span-1 text-blue-700 font-bold text-xl flex justify-center flex-col">
                        x{amount}
                    </span>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="flex flex-col w-full justifyitem-center items-center mt-20 select-none">
                <AddNetwork CCOIN={CCOIN} CCSX={CCSX} BUSD={BUSD} />

                <div className="border-0 w-full md:w-5/6 grid grid-flow-row md:grid-cols-5 grid-cols-2 gap-0 p-0 md:p-5 mt-10 rounded-xl  bg-neutral-800 bg-opacity-70 backdrop-blur-xl md:transition-all">
                    <div className="hidden md:block col-span-2 md:col-span-5 blur-sm absolute -top-4 md:right-4 text-3xl md:text-4xl self-center -mb-3 font-bold text-yellow-700 ">
                        P o o l L i s t
                    </div>
                    <div className="hidden md:block col-span-2 md:col-span-5 absolute -top-5 md:right-5 text-3xl md:text-4xl self-center -mb-3 font-bold text-white ">
                        P o o l L i s t
                    </div>

                    <div className="md:hidden -mt-7 col-span-2 md:col-span-5 text-3xl font-bold text-white z-40 text-center">
                        P o o l L i s t
                    </div>
                    <div className="w-full col-span-2 md:col-span-5 my-2 p-3 bg-white/5 rounded-lg ">
                        <span className="flex flex-row text-gray-200 justify-center items-center text-xl font-bold">
                            {nftPool.slice(0, 6) + '....' + nftPool.slice(-6)}

                            <AiOutlineCopy
                                onClick={() => {
                                    navigator.clipboard.writeText(nftPool)
                                }}
                                className="cursor-pointer hover:text-orange-400"
                            />
                        </span>
                    </div>

                    {!!account ? (
                        !!nfts.result ? (
                            nfts.result.length > 0 ? (
                                nfts.result.map((x, i) => {
                                    const metaData = JSON.parse(x.metadata)
                                    return (
                                        <Lists
                                            key={i}
                                            listID={i}
                                            amount={x.amount}
                                            _nftId={x.token_id}
                                            nftOwner={x.owner_of}
                                            nftName={metaData.name}
                                            contractType={x.contract_type}
                                            nftAddr={x.token_address}
                                            image={metaData.image}
                                            attributes={metaData.attributes}
                                        />
                                    )
                                })
                            ) : (
                                <div className=" my-10 w-full col-span-2 md:col-span-5 flex flex-row items-center text-white animate-pulse justify-center">
                                    <div id="loadingBox">No data</div>
                                </div>
                            )
                        ) : (
                            <>
                                <div className=" my-10 w-full col-span-2 md:col-span-5 flex flex-row items-center text-white animate-pulse justify-center">
                                    <div id="loadingBox">
                                        <AiOutlineLoading3Quarters
                                            color="inherit"
                                            fontSize={20}
                                            className="mx-3 font-bold animate-spin"
                                        />
                                        Loading
                                    </div>
                                </div>
                                <div className="hidden">
                                    {setTimeout(() => {
                                        try {
                                            document.getElementById(
                                                'loadingBox'
                                            ).textContent =
                                                'Unable to load data.'
                                        } catch (e) {
                                            //console.error(e)
                                        }
                                    }, 9000)}
                                </div>
                            </>
                        )
                    ) : (
                        <div className="w-full col-span-2 md:col-span-5 flex flex-row justify-center">
                            <div className="text-white text-2xl my-20">
                                Please Connect Wallet
                            </div>
                        </div>
                    )}
                </div>

                {/*///////////////////// //////////////////////////// */}
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={openBackdrop && state != 0}
                    className="flex flex-col"
                >
                    <div className="bg-gray-800 flex flex-col justify-center items-center m-4 text-center text-gray-900 text-lg p-3 rounded-lg md:w-3/5 w-full display-linebreak">
                        <div
                            onClick={() => setState(0)}
                            className={`absolute bg-black/30 w-full backdrop-blur-md backdrop-grayscale-50 z-50 items-center py-40 text-white text-3xl ${
                                !!account ? 'hidden' : null
                            }`}
                        >
                            Please Connect Wallet
                            <br />
                            <br />
                            <span className="text-sm cursor-pointer p-1 border-2 rounded-md hover:bg-black/30">
                                Close
                            </span>
                        </div>

                        <div className="w-full flex flex-row justify-between">
                            <span className="mx-3 text-white/10">#{state}</span>
                            <FaWindowClose
                                fontSize={40}
                                color="#cc0e00"
                                className={`md:transition-all duration-100 drop-shadow-lg ${
                                    state == 1 ||
                                    state == 2 ||
                                    state == 3 ||
                                    state == 5 ||
                                    state == 10 ||
                                    state == 12 ||
                                    state == 14 ||
                                    state == 16
                                        ? 'cursor-pointer hover:scale-125 '
                                        : 'grayscale opacity-10'
                                }`}
                                onClick={() => {
                                    setError('')
                                    state == 1 ||
                                    state == 2 ||
                                    state == 3 ||
                                    state == 5 ||
                                    state == 10 ||
                                    state == 12 ||
                                    state == 14 ||
                                    state == 16
                                        ? setState(0)
                                        : setState(state)
                                }}
                            />
                        </div>
                        <div className="w-full flex flex-row justify-center -mt-5">
                            <div className="py-7 text-gray-200 text-3xl font-bold">
                                {info}
                                <span className="text-rose-500 font-bold">
                                    {error}
                                </span>
                            </div>
                        </div>

                        {state == 1 ? (
                            <>
                                <div className="w-full md:w-4/5 rounded-lg drop-shadow-md flex flex-col justify-center py-7 items-center bg-black/30 ">
                                    <div className="w-11/12 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <span className="w-full flex flex-row text-gray-400 font-bold justify-center items-center hover:text-gray-100  md:transition-all">
                                            <a
                                                href={`https://bscscan.com/address/${nftAddress}`}
                                                target="_blank"
                                            >
                                                {nftAddress.slice(0, 6) +
                                                    '....' +
                                                    nftAddress.slice(-6)}
                                            </a>
                                            {verifyContract.includes(
                                                nftAddress.toLowerCase()
                                            ) ? ( // Check verify contract
                                                <MdVerified className="text-green-500 " />
                                            ) : null}
                                            <AiOutlineCopy
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        nftAddress
                                                    )
                                                }}
                                                className="cursor-pointer hover:text-orange-400"
                                            />
                                        </span>
                                    </div>

                                    <span className="w-full flex md:flex-row flex-col justify-center items-center">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="items-centers  text-white/20  place-items-center mx-16 mt-14"
                                                size={120}
                                            />
                                        ) : (
                                            <div className="w-1/3 rounded-xl md:w-1/2 -mx-5 mt-1 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-11/12 md:w-1/2 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Amount
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftAmount}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <Tooltip
                                                    title={'sellerAddress'}
                                                    placement="right"
                                                >
                                                    <span className="font-bold">
                                                        Owner
                                                    </span>
                                                </Tooltip>
                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Token Standard
                                                </span>

                                                <span className="flex flex-row text-gray-200 ">
                                                    {contractType}
                                                </span>
                                            </div>
                                            <span className="w-full text-left font-bold mb-1">
                                                Attributes
                                            </span>
                                            {!!attributes ? (
                                                <div className="bg-gray-400/5 drop-shadow-lg flex flex-col rounded-lg mt-0 p-2 text-left text-gray-400 text-sm">
                                                    {attributes.map((x, i) => (
                                                        <div
                                                            key={500 * i}
                                                            className="flex flex-row justify-between"
                                                        >
                                                            <span>
                                                                {x[0] !=
                                                                'Properties'
                                                                    ? x[0]
                                                                    : ''}
                                                            </span>
                                                            <span>{x[1]}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div />
                                            )}
                                        </span>
                                    </span>
                                </div>
                                <div className="w-full md:w-4/5 flex flex-row justify-end opacity-0 ">
                                    <div className="w-2/3 self-end flex flex-row justify-center bg-black/30 text-yellow-500 hover:bg-white/10 text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg items-center">
                                        <MdOutlineGames className="-ml-3 mr-2" />
                                        Infuse to The Game
                                    </div>
                                </div>
                            </>
                        ) : null}

                        <br />
                    </div>
                    <br />
                </Backdrop>

                {/*///////////////////// THE PRIZE //////////////////////////// */}
            </div>
        </>
    )
}

export default PoolList
