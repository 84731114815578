import { createSlice, createAsyncThunk, thunkAPI } from '@reduxjs/toolkit'
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import { ethers } from 'ethers'

export const getWalletConnect = createAsyncThunk(
    'walletConnect/enable',
    async (_, thunkAPI) => {
        const provider = await EthereumProvider.init({
            projectId: '5da8c0b4ddf6467af12892c1c23d49e8', // required
            chains: process.env.REACT_APP_CHAIN_ID == '0x38' ? [56] : [97], // required
            showQrModal: true, // requires @walletconnect/modal
            relayUrl: 'wss://relay.walletconnect.org',
            rpcMap: {
                97: 'https://data-seed-prebsc-1-s1.binance.org:8545',
                56: 'https://bsc-dataseed1.binance.org',
            },
        })
        await provider.enable()
        const accounts = await provider.request({ method: 'eth_accounts' })

        //console.log('account[0]:', accounts[0])
        thunkAPI.dispatch(
            setAccount({
                account: accounts[0],
                provider: provider,
                web3Provider: new ethers.providers.Web3Provider(provider),
            })
        )
    }
)

const userSlice = createSlice({
    name: 'walletConnect',
    initialState: {
        value: {
            account: '',
            provider: '',
            web3Provider: '',
        },
    },
    reducers: {
        // setStableCoin, setUsd , setUseWc, transfer, setProvidor, enableProvidor, disconnect

        setAccount: (state, action) => {
            console.log('SET ACCOUNT', action.payload)
            state.value = action.payload
        },
        clearAccount: (state, action) => {
            console.log('CLEAR ACCOUNT', action.payload)
            state.value = {}
        },
    },
    extraReducers(builder) {
        builder.addCase(getWalletConnect.fulfilled, (state, action) => {
            state.status = 'Connected!'
            state.valuse = action.payload
        })
    },
})

export const { setAccount, clearAccount } = userSlice.actions
export default userSlice.reducer
