import { useState, useEffect } from 'react'
import axios from 'axios'
import TimeAgo from 'javascript-time-ago'
import Rate from './Rate'
import en from 'javascript-time-ago/locale/en'

TimeAgo.addDefaultLocale(en)
const timeAgo = new TimeAgo('en-US')

function toTimeAgo(timestamp) {
    return timeAgo.format(new Date(timestamp * 1000), 'mini')
}

function Tx({ buyer, txhash, timestamp, nfts }) {
    const spanNum = nfts.length <= 3 ? nfts.length : 3

    return (
        <>
            <div className={`w-full col-span-${spanNum} `}>
                <a href={`address/${buyer}`} target="_blank">
                    <div className="bg-white/30 rounded-md m-1 hover:bg-pink-600/20">
                        <div className="flex justify-center text-xs text-black font-bold p-1 rounded-lg ">
                            {buyer.slice(0, 6) + '....' + buyer.slice(-6)}
                        </div>

                        <div className="flex flex-wrap justify-around rounded-lg overflow-hidden">
                            {nfts.slice(0, 6).map((x, i) => (
                                <div
                                    key={i * 4}
                                    className="min-w-[126px] w-[30%] mb-3 rounded-lg bg-white overflow-hidden drop-shadow-lg"
                                >
                                    <div
                                        className={`absolute top-12 right-0 rounded-l-lg flex justify-center drop-shadow-lg text-2xl font-bold text-white bg-black/20 p-1 ${
                                            i != 5 ? 'hidden' : ''
                                        }`}
                                    >
                                        +{nfts.length - 6} More
                                    </div>
                                    <img
                                        className={`${
                                            x.identifier == 199 ||
                                            x.identifier == 198 ||
                                            x.identifier == 122
                                                ? 'blur-sm opacity-70'
                                                : ''
                                        }`}
                                        src={x.image}
                                    />

                                    <div className="w-full -mt-6 pr-2 flex flex-row justify-between items-center drop-shadow-lg text-xs font-bold text-white bg-black/20 py-1">
                                        <div className="-mt-7">
                                            <Rate rate={x.rate} />
                                        </div>
                                        <div>#{x.identifier}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="flex justify-center text-xs text-black -mt-3 p-1 font-bold">
                            {toTimeAgo(timestamp)} ago
                        </div>
                    </div>
                </a>
            </div>
        </>
    )
}

function TxList({ contract }) {
    const [txData, setTxData] = useState([])
    const [loading, setLoading] = useState(false)

    const parseNFTMetadata = async function (
        metadata,
        ipfs_gateway = 'https://ipfs.8api.sh/ipfs/'
    ) {
        const applyIPFSGateway = function (uri) {
            if (uri instanceof Array) {
                return uri.map((i) => applyIPFSGateway(i))
            } else if (typeof uri === 'object') {
                Object.keys(uri).forEach((k) => {
                    uri[k] = applyIPFSGateway(uri[k])
                })
                return uri
            } else if (typeof uri === 'string') {
                return uri.replace('ipfs://', ipfs_gateway)
            } else {
                return uri
            }
        }
        metadata = applyIPFSGateway(metadata)
        try {
            const URI = new URL(metadata)
            const response = await axios.get(metadata)
            return applyIPFSGateway(response.data)
        } catch (e) {
            return metadata
        }
    }

    async function getTxList(contract, num = 10) {
        console.log('contract;', contract)
        try {
            const txListResponse = await axios.get(
                `https://exp-l1.jibchain.net/api?module=account&action=txlist&address=${contract}&startblock=0&endblock=99999999&page=1&offset=${num}&sort=desc`
            )

            const transactions = txListResponse.data.result
            setLoading(true)
            for (let tx of transactions) {
                // Filter transactions based on the input value
                if (
                    tx.input.startsWith('0x1517014e') ||
                    tx.input.startsWith('0xbff29cee')
                ) {
                    const txhash = tx.hash

                    const graphqlResponse = await axios.post(
                        'https://graph.jibchain.net/subgraphs/name/jbc/all',
                        {
                            query: `{
                                transaction(
                                    id: "${txhash}"
                                  ){
                                    events {
                                        ... on ERC721Transfer {
                                            token {
                                                identifier
                                                uri
                                            }
                                        }
                                    }
                                }
                            }`,
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        }
                    )

                    const eventData =
                        graphqlResponse.data.data.transaction.events
                    const erc721Events = eventData.filter(
                        (event) => event.token
                    )

                    // Fetch metadata for each NFT and apply IPFS gateway
                    const nftDetails = await Promise.all(
                        erc721Events.map(async (event) => {
                            let metadataUri = event.token.uri
                            let metadata = await parseNFTMetadata(metadataUri)
                            let _rate = 'none'

                            for (const attribute of metadata.attributes) {
                                if (attribute.trait_type === 'Rate') {
                                    _rate = attribute.value // Assign the rate
                                    break
                                }
                            }

                            return {
                                identifier: event.token.identifier,
                                uri: metadataUri,
                                image: metadata.image,
                                rate: _rate,
                            }
                        })
                    )

                    const txDetail = {
                        buyer: tx.from,
                        timestamp: tx.timeStamp,
                        txhash: txhash,
                        nfts: nftDetails,
                    }

                    setTxData((prevData) => [...prevData, txDetail])
                }
            }
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error)
            setLoading(false)
        }
    }

    useEffect(() => {
        setTxData([])
        getTxList(contract, 10)
    }, [])

    //console.log('txData', txData)

    return (
        <div className="md:w-2/3 max-w-[512px] grid grid-flow-row grid-cols-3 gap 1">
            {txData.map((_tx, i) => (
                <Tx
                    key={i * 7}
                    buyer={_tx.buyer}
                    txhash={_tx.txhash}
                    timestamp={_tx.timestamp}
                    nfts={_tx.nfts}
                />
            ))}
        </div>
    )
}

export default TxList
