import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FaArrowAltCircleDown } from 'react-icons/fa'
import { BiLoaderAlt } from 'react-icons/bi'
import { RiCloseCircleFill } from 'react-icons/ri'
import { ethers } from 'ethers'
import TxList from './TxList'
import Select from 'react-select'
import Backdrop from '@mui/material/Backdrop'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import AnimatedNumber from 'animated-number-react'
import AddNetwork from './AddNetwork'
import Confetti from './Confetti'
import SteplineInfuse from './SteplineInfuse'
import { MdOutlineAccountBalanceWallet } from 'react-icons/md'
import { useSelector } from 'react-redux'
import axios from 'axios'
/* global BigInt */
const USDTAddr = '0x55d398326f99059fF775485246999027B3197955'
const BUSDAddr = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
const CCSXAddr = '0x1819615B0643c54A6096af42a72C761f85E7ec37'
const PTC = '0xA9B5594F1FC4936Da6a34E260b3B9cc0de8c6A3d'
const SODSAI = '0x771De487691B2365De462F011c5Ad2e866753A26'

// PACKAGE CONFIG //
const package1Price = 50
const package1Get = 1750 + 250
const package2Price = 200
const package2Get = 7000 + 1250
const package3Price = 500
const package3Get = 17500 + 3500
const package4Price = 1000
const package4Get = 35000 + 8000
////////////////////

const ERC721ABI = require('../abi/erc721')
const formatValue2 = (value) => `${separator(Number(value).toFixed(2))}`
const formatValue0 = (value) => `${separator(Number(value).toFixed(0))}`
const isMetaMaskInstalled = () => {
    const { ethereum } = window
    return Boolean(ethereum && ethereum.isMetaMask)
}

function separator(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function Loader() {
    return (
        <button
            type="button"
            className="text-gray-500 w-full mt-2 border-[1px] p-2 border-gray-300 rounded-full cursor-not-allowed flex flex-row items-center justify-center"
        >
            <BiLoaderAlt className="animate-spin mr-2" />
            <span className="animate-pulse">Processing... </span>
        </button>
    )
}

function numberOfNFTs(inputAmount, currentTotalSupply) {
    const basePrice = 20
    const priceIncrement = 3
    const nftPerTranche = 100

    let nftsPurchasable = 0
    let remainingAmount = inputAmount

    while (remainingAmount > 0) {
        let currentPrice =
            basePrice +
            Math.floor(currentTotalSupply / nftPerTranche) * priceIncrement

        let nextThreshold =
            Math.floor(currentTotalSupply / nftPerTranche) * nftPerTranche +
            nftPerTranche

        let nftsAvailableAtCurrentPrice = nextThreshold - currentTotalSupply
        let affordableNfts = Math.floor(remainingAmount / currentPrice)
        let nftsToBuy = Math.min(affordableNfts, nftsAvailableAtCurrentPrice)

        if (nftsToBuy === 0) {
            break // Break if no NFTs can be bought at the current price with the remaining amount
        }

        nftsPurchasable += nftsToBuy
        remainingAmount -= nftsToBuy * currentPrice
        currentTotalSupply += nftsToBuy
    }

    return nftsPurchasable
}

function NFTGallery({ _nfts, _buyWithJBC = true }) {
    const [loadedCount, setLoadedCount] = useState(0)

    const handleImageLoad = () => {
        setLoadedCount((currentCount) => currentCount + 1)
    }

    const allImagesLoaded = loadedCount === _nfts.length

    return (
        <div>
            {allImagesLoaded ? <Confetti className="absolute z-50" /> : null}
            {
                <div className="w-full flex flex-wrap justify-around">
                    {_nfts.slice(0, 7).map((x, i) => (
                        <div
                            key={200 * i}
                            className="md:w-1/4 w-1/2 flex flex-row justify-center"
                        >
                            <div className="w-[128px] overflow-hidden m-1">
                                <img
                                    className="md:transition-all duration-1000 drop-shadow-xl rounded-md"
                                    src={x.metadata.image}
                                    onLoad={handleImageLoad}
                                />
                                <div className="bg-black/20 -mt-12 p-1 text-white drop-shadow-md text-sm font-bold z-50 truncate rounded-b-md">
                                    {x.metadata.name}
                                    <br />#{x.token.identifier}
                                </div>
                            </div>
                        </div>
                    ))}
                    {_nfts.length > 7 ? (
                        <div
                            key={300 * _nfts.length}
                            className="md:w-1/4 w-1/2 flex flex-row justify-center"
                        >
                            <div className="w-[128px] overflow-hidden m-1">
                                <img
                                    className="md:transition-all duration-1000 drop-shadow-xl rounded-md"
                                    src={_nfts[7].metadata.image}
                                />
                                <div className="bg-black/20 text-4xl -mt-12 p-1 text-white drop-shadow-md font-bold z-50 truncate rounded-b-md">
                                    +{_nfts.length - 7}
                                </div>
                            </div>
                        </div>
                    ) : _nfts.length == 7 ? (
                        <div
                            key={300 * _nfts.length}
                            className="md:w-1/4 w-1/2 flex flex-row justify-center"
                        >
                            <div className="w-[128px] overflow-hidden m-1">
                                <img
                                    className="md:transition-all duration-1000 drop-shadow-xl rounded-md"
                                    src={_nfts[7].metadata.image}
                                />
                                <div className="bg-black/20 -mt-12 p-1 text-white drop-shadow-md text-sm font-bold z-50 truncate rounded-b-md">
                                    {_nfts[7].metadata.name}
                                    <br />#{_nfts[7].token.identifier}
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {_buyWithJBC ? (
                        <div
                            key={900}
                            className="md:w-1/4 w-1/2 flex flex-row justify-center"
                        >
                            <div className="w-[128px] flex flex-row items-center justify-center text-3xl font-bold overflow-hidden m-1 bg-pink-500/20 rounded-md">
                                <img
                                    className="mx-2"
                                    width={24}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/coins/ptc.png'
                                    }
                                />{' '}
                                {_nfts.length}
                            </div>
                        </div>
                    ) : null}
                </div>
            }
        </div>
    )
}

function currentNFTPrice(totalSupply) {
    const basePrice = 20 // Starting price of NFT
    const increment = 3 // Price increment for every 100 units of supply
    const incrementInterval = 100 // Interval of supply for price increment

    // Calculate how many increments have occurred
    const incrementsOccurred = Math.floor(totalSupply / incrementInterval)

    // Calculate the current price
    const currentPrice = basePrice + incrementsOccurred * increment

    return currentPrice
}

function Sodsai() {
    const [state, setState] = useState(0)
    const [txData, setTxData] = useState([])
    const [ptcBalance, setPtcBalance] = useState(0)
    const [ptc, setPtc] = useState(0)
    const [jbc, setJbc] = useState(0)
    const [isProcess, setIsProcess] = useState(false)
    const [error, setError] = useState()
    const [info, setInfo] = useState()
    const [ustdBalance, setUsdtBalance] = useState(0)
    const [open, setOpen] = useState(false)
    const [focusedInput, setFocus] = useState(null)
    const [coin, setCoin] = useState(USDTAddr)
    const [rate, setRate] = useState()
    const [account, setAccount] = useState(null)
    const [ETHBalance, setETHBalance] = useState(0)
    const [totalSupply, setTotalSupply] = useState(0)
    const [nfts, setNfts] = useState([])
    const [payBy, setPayBy] = useState('JBC')

    let bonus = 0
    let total = 0

    const handleClose = () => {
        setOpen(false)
    }
    const handleToggle = () => {
        setOpen(!open)
    }
    const wc = useSelector((state) => state.walletConnect.value)

    let provider
    let ethereum = window.ethereum
    let tempSigner

    if (!!wc.account) {
        ethereum = wc.provider
        provider = wc.web3Provider
        tempSigner = provider.getSigner()
        ethereum.on('accountsChanged', (accounts) => setState(0))
    } else if (isMetaMaskInstalled()) {
        ethereum = window.ethereum
        provider = new ethers.providers.Web3Provider(ethereum)
        tempSigner = provider.getSigner()
        ethereum.on('accountsChanged', (accounts) => setState(0))
    }

    useEffect(() => {
        console.clear()
        //console.log('100:', currentNFTPrice(2236))
        if (!!wc.account || isMetaMaskInstalled()) {
            getCurrentAccount()
            getETHBalance()
            getPTCBalance()
            getTotalSupply(SODSAI)
        }

        return () => {
            provider = null
            tempSigner = null
        }
    }, [])

    let IERC20 = require('../abi/IERC20')

    if (jbc == package1Price && coin != CCSXAddr) {
        bonus = package1Get - ptcBalance
        total = package1Get
    } else if (jbc == package2Price && coin != CCSXAddr) {
        bonus = package2Get - ptcBalance
        total = package2Get
    } else if (jbc == package3Price && coin != CCSXAddr) {
        bonus = package3Get - ptcBalance
        total = package3Get
    } else if (jbc == package4Price && coin != CCSXAddr) {
        bonus = package4Get - ptcBalance
        total = package4Get
    } else {
        total = ptcBalance
        bonus = 0
    }

    async function getCurrentAccount() {
        const accounts = await ethereum.request({
            method: 'eth_accounts',
        })
        setAccount(accounts[0])
        return accounts[0]
    }

    async function getETHBalance() {
        try {
            const address = await tempSigner.getAddress()
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const balance = await provider.getBalance(address)
            const formattedBalance = ethers.utils.formatEther(balance)
            const numericBalance = parseFloat(formattedBalance)
            setETHBalance(numericBalance)
        } catch (err) {
            console.log('Error:', err)
        }
    }

    async function getPTCBalance() {
        const constract = new ethers.Contract(PTC, IERC20, tempSigner)
        try {
            await constract.balanceOf(tempSigner.getAddress()).then((x) => {
                setPtcBalance(ethers.utils.formatEther(x))
                //console.log('PTC :', ethers.utils.formatEther(x))
            })
        } catch (err) {
            console.error(err)
        }
    }

    async function getTotalSupply(_contract) {
        await axios
            .get(
                `https://exp-l1.jibchain.net/api?module=stats&action=tokensupply&contractaddress=${_contract}`
            )
            .then((x) => setTotalSupply(parseInt(x.data.result)))
        return
    }

    async function buyNFT(_jbc) {
        const contract = new ethers.Contract(SODSAI, ERC721ABI, tempSigner)
        const etherValue = ethers.utils.parseEther(_jbc.toString())
        try {
            const tx = await contract
                .buyNFT({
                    value: etherValue,
                })
                .catch((error) => {
                    console.error(error)
                    setIsProcess(false)
                    setError('Payment error, please check your wallet balance')
                    handleClose()
                })

            setInfo('Please wait for a block confirmation')
            const receipt = await tx
                .wait(1)
                .then((x) => {
                    //console.log('tx:', x)
                    console.log('txHash:', x.transactionHash)
                    if (x.confirmations >= 1) {
                        console.log('Confirmed:', x.confirmations)
                    }
                    console.log('JBC Check :', x.to) // JBC contract address
                    setJbc(0)
                    getPTCBalance()
                    setIsProcess(false)

                    returnTxData(x.transactionHash)
                    setOpen(true)
                    setState(2)
                    setInfo("Congratulation You've got...")
                })
                .catch((error) => {
                    console.log('error code:', error.code)
                    console.error(error)
                    setIsProcess(false)
                    //document.getElementById('jbc').removeAttribute('disabled')
                    handleClose()
                })
        } catch (error) {
            console.log(error)
        }
    }
    /////////////////////////////////////////////////////////////

    async function getPTCpprove(_amount, _nftContract, _nextStep) {
        const numNfts = parseInt(_amount)
        try {
            const contract = new ethers.Contract(PTC, IERC20, tempSigner)
            const requiredPTCAmount = ethers.utils.parseEther(
                (numNfts * 10).toString()
            )
            const ptcBalance = await contract.balanceOf(tempSigner.getAddress())

            if (ptcBalance.gte(requiredPTCAmount)) {
                const currentAllowance = await contract.allowance(
                    tempSigner.getAddress(),
                    _nftContract
                )
                const additionalAllowanceNeeded =
                    requiredPTCAmount.sub(currentAllowance)

                if (additionalAllowanceNeeded.gt(ethers.constants.Zero)) {
                    setInfo('Updating PTC Approval on Metamask')

                    // Increase the allowance
                    const tx = await contract.increaseAllowance(
                        _nftContract,
                        additionalAllowanceNeeded
                    )
                    setInfo('Waiting for block confirmation')
                    await tx.wait(1)
                    setState(_nextStep)
                    buyNFTWithPtc(numNfts)
                    setInfo('Confirm Transaction on Metamask')
                } else {
                    setState(_nextStep)
                    buyNFTWithPtc(numNfts)
                    setInfo('Confirm Transaction on Metamask')
                }
            } else {
                setInfo('')
                setError('Insufficient PTC Balance.')
                setState(0)
            }
        } catch (err) {
            console.error('Error in getPTCpprove:', err)
            setState(0)
            handleClose()
            setIsProcess(false)
        }
    }

    async function buyNFTWithPtc(_ptc) {
        const contract = new ethers.Contract(SODSAI, ERC721ABI, tempSigner)

        const numNfts = parseInt(_ptc)
        console.log('buyNFTWithPtc:', ethers.utils.parseEther(_ptc.toString()))
        //ethers.utils.formatEther(checkAllowance)
        try {
            const tx = await contract.buyNFTWithPtc(numNfts).catch((error) => {
                console.error(error)
                setIsProcess(false)
                setError('Payment error, please check your wallet balance')
                handleClose()
            })

            setInfo('Please wait for a block confirmation')
            const receipt = await tx
                .wait(1)
                .then((x) => {
                    //console.log('tx:', x)
                    console.log('txHash:', x.transactionHash)
                    if (x.confirmations >= 1) {
                        console.log('Confirmed:', x.confirmations)
                    }
                    console.log('JBC Check :', x.to) // JBC contract address
                    setJbc(0)
                    getPTCBalance()
                    setIsProcess(false)

                    returnTxData(x.transactionHash)
                    setOpen(true)
                    setState(2)
                    setInfo("Congratulation You've got...")
                })
                .catch((error) => {
                    console.log('error code:', error.code)
                    console.error(error)
                    setIsProcess(false)
                    //document.getElementById('jbc').removeAttribute('disabled')
                    handleClose()
                })
        } catch (error) {
            console.log(error)
        }
    }

    /////////////////////////////////////////////////////////////

    async function returnTxData(_tx) {
        const parseNFTMetadata = async function (
            metadata,
            ipfs_gateway = 'https://ipfs.8api.sh/ipfs/'
            //ipfs_gateway = 'https://ipfs.io/ipfs/'
        ) {
            const applyIPFSGateway = function (uri) {
                if (uri instanceof Array) {
                    return uri.map((i) => applyIPFSGateway(i))
                } else if (typeof uri === 'object') {
                    Object.keys(uri).forEach((k) => {
                        uri[k] = applyIPFSGateway(uri[k])
                    })
                    return uri
                } else if (typeof uri === 'string') {
                    return uri.replace('ipfs://', ipfs_gateway)
                } else {
                    return uri
                }
            }
            metadata = applyIPFSGateway(metadata)
            try {
                const URI = new URL(metadata)
                const response = await axios.get(metadata)
                return applyIPFSGateway(response.data)
            } catch (e) {
                return metadata
            }
        }
        try {
            const response = await axios.post(
                'https://graph.jibchain.net/subgraphs/name/jbc/all',
                {
                    query: `{
                        transaction(
                            id: "${_tx}"
                          ){
                            events {
                              ... on ERC721Transfer {
                                token {
                                  identifier
                                  uri
                                }
                              }
                              ... on ERC20Transfer {
                                value
                                contract {
                                  symbol
                                }
                              }
                            }
                          }
                          
                    }`,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )

            const events = response.data
                ? response.data.data.transaction.events
                : null

            if (events) {
                const erc721 = events.filter((item) => item.token)
                // Fetch and apply metadata for each NFT
                for (let nft of erc721) {
                    nft.metadata = await parseNFTMetadata(nft.token.uri)
                    console.log('nft:', nft)
                }
                console.log('erc721:', erc721)
                setNfts(erc721)
                return
            }
            setIsProcess(false)
            handleClose()
            return null
        } catch (error) {
            console.error('Error fetching data:', error)
            setIsProcess(false)
            handleClose()
            return null
        }
    }

    ////////////////////////////////////////////////////////////

    const options = [
        {
            value: 'jbc',
            label: (
                <div className="flex flex-row text-sm font-bold justify-between items-center">
                    <img
                        src={process.env.PUBLIC_URL + '/img/coins/jbccoin.png'}
                    />
                    JBC
                </div>
            ),
        },
        {
            value: 'busd',
            label: (
                <div className="flex flex-row text-sm font-bold justify-between items-center">
                    <img src={process.env.PUBLIC_URL + '/img/coins/ptc.png'} />
                    PTC
                </div>
            ),
        },
    ]

    return (
        <div className="flex flex-col  w-full justifyitem-center items-center mt-20 select-none">
            <div className="w-full absolute bg  -mt-7 opacity-60"></div>

            <div className="mt-16 w-full p-6 flex flex-col justifyitem-center items-center drop-shadow-lg rounded-md bg-neutral-100 bg-opacity-50">
                <div className="text-4xl">
                    SODS<span className="text-red-500">AI</span>
                </div>
                <div>Collection#1</div>

                <div className="flex flex-row mt-2 md:w-1/2 w-full text-justify">
                    A gallery of digital art that celebrates uniqueness,
                    creativity, and the joy of color. This collection is perfect
                    for collectors and enthusiasts who appreciate art that
                    combines fashion, fantasy, and vibrant digital
                    craftsmanship.
                </div>
            </div>

            <AddNetwork PTC={PTC} CCSX={CCSXAddr} />
            <div className="md:w-2/3 max-w-[512px] mt-24 w-full p-5 flex flex-col justifyitem-center items-center drop-shadow-lg rounded-3xl border-[#4b4314] bg-neutral-100 bg-opacity-40 backdrop-blur-md z-0">
                <div className="w-full flex flex-row justify-start">
                    <div className="rounded-t-xl w-1/3 bg-white hover:-translate-y-2 transition-all duration-300 pb-5 -mb-5 cursor-pointer">
                        <div
                            onClick={() => setPayBy('JBC')}
                            className="flex flex-row text-sm font-bold justify-start items-center "
                        >
                            <img
                                className="m-2"
                                src={
                                    process.env.PUBLIC_URL +
                                    '/img/coins/jbccoin.png'
                                }
                            />
                            JBC
                        </div>
                    </div>
                    <div
                        onClick={() => setPayBy('PTC')}
                        className="rounded-t-xl w-1/3 bg-pink-100 hover:-translate-y-2 transition-all duration-300 pb-3 -mb-3 cursor-pointer z-10"
                    >
                        <div className="flex flex-row text-sm font-bold justify-start items-center">
                            <img
                                className="m-2"
                                src={
                                    process.env.PUBLIC_URL +
                                    '/img/coins/ptc.png'
                                }
                            />
                            PTC
                        </div>
                    </div>
                </div>
                {payBy == 'JBC' ? (
                    <div className="rounded-xl w-full bg-white z-20 transition-all">
                        <div className=" w-full flex justify-between my-2">
                            <span className="text-slate-500 text-sm px-3">
                                Your Balance{' '}
                            </span>

                            <span className="flex flex-row items-center place-items-center text-slate-500 text-sm pr-3">
                                <img
                                    className="scale-75 mt-0"
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/coins/jbccoin.png'
                                    }
                                />
                                <AnimatedNumber
                                    value={ETHBalance}
                                    formatValue={formatValue2}
                                    duration="500"
                                />
                            </span>
                        </div>
                        <div className=" w-full flex justify-between my-0">
                            <span className="text-slate-500 text-xs p-3">
                                Amount of JBC you will spend
                            </span>
                            <span className="text-slate-500 text-xs p-3 flex flex-row">
                                <div
                                    onClick={() => setJbc(Number(jbc) + 3)}
                                    className="rounded-md border border-gray-300 p-1 ml-1 hover:cursor-pointer"
                                >
                                    3
                                </div>

                                <div
                                    onClick={() => setJbc(Number(jbc) + 20)}
                                    className="rounded-md border border-gray-300 p-1 ml-1 hover:cursor-pointer"
                                >
                                    20
                                </div>
                                <div
                                    onClick={() => setJbc(Number(jbc) + 100)}
                                    className="rounded-md border border-gray-300 p-1 ml-1 hover:cursor-pointer"
                                >
                                    100
                                </div>
                                <div
                                    onClick={() => setJbc(Number(jbc) + 500)}
                                    className="rounded-md border border-gray-300 p-1 ml-1 hover:cursor-pointer"
                                >
                                    500
                                </div>
                                <div
                                    onClick={() => setJbc(Number(jbc) + 1000)}
                                    className="rounded-md border border-gray-300 p-1 ml-1 hover:cursor-pointer"
                                >
                                    1,000
                                </div>
                                <div
                                    onClick={() =>
                                        setJbc(
                                            Number(
                                                Math.floor(ETHBalance).toFixed(
                                                    0
                                                )
                                            )
                                        )
                                    }
                                    className="rounded-md border border-gray-300 p-1 ml-1 hover:cursor-pointer"
                                >
                                    Max
                                </div>
                            </span>
                        </div>
                        <div className=" w-full flex justify-between">
                            <div />
                            <input
                                placeholder="JBC"
                                type="text"
                                pattern="[0-9]*"
                                min="400"
                                step="1"
                                id="jbc"
                                keyboardtype="decimal-pad"
                                value={jbc}
                                autoComplete="off"
                                //onFocus={() => setFocus('usd')}
                                onChange={(e) => {
                                    const re = /^[0-9\b]+$/
                                    if (
                                        e.target.value === '' ||
                                        re.test(e.target.value)
                                    ) {
                                        setJbc(e.target.value)
                                    }
                                }}
                                className="text-right my-2 w-2/5 active:outline-none rounded-sm pr-10 outline-non bg-transparent text-slate-500 border-none text-3xl font-bold focus:outline-none"
                            />
                            <RiCloseCircleFill
                                className="absolute right-10 text-slate-400/50 hover:text-rose-500 hover:cursor-pointer"
                                fontSize={18}
                                onClick={() => {
                                    setJbc(0)
                                }}
                            />
                        </div>

                        <div className=" w-full flex justify-between mb-2">
                            <span className="text-red-800 text-sm px-3">
                                Estimate NFT amount{' '}
                            </span>

                            <span className="flex flex-row items-center place-items-center text-red-800 text-3xl font-bold pr-10 ">
                                <AnimatedNumber
                                    value={numberOfNFTs(jbc, totalSupply)}
                                    formatValue={formatValue0}
                                    duration="500"
                                />
                            </span>
                        </div>
                    </div>
                ) : (
                    <div className="rounded-xl w-full bg-pink-100 z-20 transition-all">
                        <div className=" w-full flex justify-between my-2">
                            <span className="text-red-800 text-sm px-3">
                                Your Balance{' '}
                            </span>

                            <span className="flex flex-row items-center place-items-center text-red-800 text-sm pr-3">
                                <img
                                    className="scale-75 mt-0"
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/coins/ptc.png'
                                    }
                                />
                                <AnimatedNumber
                                    value={ptcBalance}
                                    formatValue={formatValue0}
                                    duration="200"
                                />
                            </span>
                        </div>
                        <div className=" w-full flex justify-between my-0">
                            <span className="text-red-800 text-xs p-3">
                                Number of NFT
                            </span>
                            <span className="text-red-800 text-xs p-3 flex flex-row">
                                <div
                                    onClick={() => setPtc(Number(ptc) + 1)}
                                    className="rounded-md border border-red-200 p-1 ml-1 hover:cursor-pointer"
                                >
                                    1
                                </div>

                                <div
                                    onClick={() => setPtc(Number(ptc) + 3)}
                                    className="rounded-md border border-red-200 p-1 ml-1 hover:cursor-pointer"
                                >
                                    3
                                </div>
                                <div
                                    onClick={() => setPtc(Number(ptc) + 5)}
                                    className="rounded-md border border-red-200 p-1 ml-1 hover:cursor-pointer"
                                >
                                    5
                                </div>
                                <div
                                    onClick={() => setPtc(Number(ptc) + 10)}
                                    className="rounded-md border border-red-200 p-1 ml-1 hover:cursor-pointer"
                                >
                                    10
                                </div>
                            </span>
                        </div>
                        <div className=" w-full flex justify-between">
                            <div />
                            <input
                                placeholder="PTC"
                                type="text"
                                pattern="[0-9]*"
                                min="400"
                                step="1"
                                id="ptc"
                                keyboardtype="decimal-pad"
                                value={ptc}
                                autoComplete="off"
                                onFocus={() => setFocus('usd')}
                                onChange={(e) => {
                                    const re = /^[0-9\b]+$/
                                    if (
                                        e.target.value === '' ||
                                        re.test(e.target.value)
                                    ) {
                                        setPtc(e.target.value)
                                    }
                                }}
                                className="text-right my-2 w-2/5 active:outline-none rounded-sm pr-10 outline-non bg-transparent text-slate-500 border-none text-3xl font-bold focus:outline-none"
                            />
                            <RiCloseCircleFill
                                className="absolute right-10 text-slate-400/50 hover:text-rose-500 hover:cursor-pointer"
                                fontSize={18}
                                onClick={() => {
                                    setPtc(0)
                                }}
                            />
                        </div>

                        <div className=" w-full flex justify-between mb-2">
                            <span className="text-red-800 text-sm px-3">
                                Total PTC
                                <br />
                                {ptc} ea x 10 =
                            </span>

                            <span className="flex flex-row items-center place-items-center text-red-800 text-3xl font-bold pr-10 ">
                                <AnimatedNumber
                                    value={ptc * 10}
                                    formatValue={formatValue0}
                                    duration="500"
                                />
                            </span>
                        </div>
                    </div>
                )}

                {isProcess ? (
                    <Loader />
                ) : (
                    <button
                        type="button"
                        onClick={() => {
                            if (payBy == 'JBC') {
                                //////////// BUY WITH JBC ////////////
                                if (jbc < currentNFTPrice(totalSupply)) {
                                    setError(
                                        `${currentNFTPrice(
                                            totalSupply
                                        )} JBC Minimum Purchase required`
                                    )
                                } else {
                                    setInfo(
                                        'Waiting for your confirmation on Metamask'
                                    )
                                    handleToggle()
                                    setState(1)
                                    setIsProcess(true)
                                    setError('')
                                    buyNFT(jbc)
                                }
                            } else if (payBy == 'PTC') {
                                //////////// BUY WITH PTC ////////////
                                if (ptc * 10 > ptcBalance) {
                                    setError(
                                        `Insufficient
                                        PTC Amount`
                                    )
                                } else {
                                    setInfo('Approve your PTC')
                                    handleToggle()
                                    setState(3)
                                    setIsProcess(true)
                                    setError('')
                                    getPTCpprove(ptc, SODSAI, 4)
                                }
                            }
                        }}
                        className="text-white w-full mt-2  p-2 hover:animate-pulse  bg-pink-400 rounded-xl cursor-pointer transition-all hover:bg-pink-600"
                    >
                        Mint!
                    </button>
                )}
                <span className="text-red-600 font-bold text-sm my-1">
                    {error}
                </span>
            </div>

            <div className="md:w-2/3 max-w-[512px] mt-3 w-full p-1 flex flex-row justifyitem-center items-center drop-shadow-lg rounded-xl border-[#4b4314] bg-neutral-100 bg-opacity-50 backdrop-blur-md">
                <div className="w-1/2 rounded-xl border-2 border-pink-200 bg-white p-2 flex flex-col justify-center items-center m-2">
                    <span className="flex flex-row justify-center items-center">
                        Total Minted
                    </span>
                    <AnimatedNumber
                        value={totalSupply}
                        formatValue={formatValue0}
                        duration="500"
                        className="text-3xl font-bold"
                    />
                </div>

                <div className="w-1/2 rounded-xl border-2 border-pink-200 bg-white p-2 flex flex-col justify-center">
                    <span className="flex flex-row justify-center items-center">
                        Current Price
                    </span>

                    <div className="flex flex-row text-sm font-bold justify-center items-center">
                        <img
                            className="mx-1"
                            src={
                                process.env.PUBLIC_URL +
                                '/img/coins/jbccoin.png'
                            }
                        />
                        <AnimatedNumber
                            value={currentNFTPrice(totalSupply)}
                            formatValue={formatValue0}
                            duration="500"
                            className="text-3xl font-bold"
                        />
                    </div>
                </div>
            </div>

            <Link
                to="/Herup"
                className="h-[260px] bg-gradient-to-t overflow-hidden from-purple-500 to-pink-400 md:w-2/3 max-w-[512px] mt-3 w-full flex flex-row justifyitem-center items-center drop-shadow-lg rounded-xl border-[#4b4314] bg-neutral-100 bg-opacity-50 backdrop-blur-md"
            >
                <img
                    className="opacity-30 mt-9 transition-all hover:opacity-90 duration-700"
                    src={process.env.PUBLIC_URL + '/img/herupcover.png'}
                />
                <div className="absolute w-full  top-0 rounded-xl bg-white/0 drop-shadow-md flex flex-col justify-center items-center">
                    <div className="w-full bg-white/30 mt-9 p-3 flex justify-center drop-shadow-md">
                        <img
                            width={200}
                            src={process.env.PUBLIC_URL + '/img/heruplogo.png'}
                        />
                    </div>

                    <span className="mt-2 flex flex-row justify-center items-center font-bold text-2xl text-white hover:text-pink-200 hover:scale-110 transition-all duration-500">
                        Exclusive NFT for
                        <img
                            className="mx-1"
                            width={20}
                            src={process.env.PUBLIC_URL + '/img/coins/ptc.png'}
                        />
                        <span className="text-black mr-2">PTC</span> Mint! Only
                    </span>
                </div>
            </Link>

            <Link
                to="/Mirrorme"
                className="bg-gradient-to-t overflow-hidden from-purple-900/50 to-pink-500/40 md:w-2/3 max-w-[512px] mt-3 w-full flex flex-row justifyitem-center items-center drop-shadow-lg rounded-xl border-[#4b4314] bg-neutral-100 bg-opacity-50 backdrop-blur-md"
            >
                <img
                    className="opacity-30 transition-all hover:opacity-90 duration-700"
                    src={process.env.PUBLIC_URL + '/img/mrmmini.png'}
                />
                <div className="absolute w-full top-0 rounded-xl bg-black/0 drop-shadow-md flex flex-col justify-center items-center">
                    <div className="w-full bg-pink-800/30 mt-6 p-3 flex justify-center drop-shadow-md">
                        <img
                            width={200}
                            src={
                                process.env.PUBLIC_URL + '/img/MirrorMeLogo.png'
                            }
                        />
                    </div>
                    <span className="flex flex-row justify-center items-center text-base p-5 text-zinc-50 text-justify">
                        Only 10 JBC/mint, get 1 PTC/mint extra!!
                    </span>
                    <span className="flex flex-row justify-center items-center font-bold text-2xl text-white hover:text-zinc-400 hover:scale-110 transition-all">
                        Mint Her Now!
                    </span>
                </div>
            </Link>

            <div className="md:w-2/3 px-2 font-bold text-pink-500/70 justify-center max-w-[512px] mt-3 mb-1 w-full p-1 flex flex-row justifyitem-center items-center drop-shadow-lg rounded-xl border-[#4b4314] bg-neutral-100 bg-opacity-50 backdrop-blur-md">
                Recently Mint
            </div>
            <TxList contract={SODSAI} />
            <div className="mt-20 md:w-2/3 w-full p-6 flex flex-col justifyitem-center items-center drop-shadow-lg ">
                <div className="grid grid-flow-row md:grid-cols-2 grid-cols-1 gap-3 justify-center items-center rounded-xl drop-shadow-lg  p-5 bg-neutral-100/70 transition-all duration-300">
                    <div className="flex my-2 flex-row md:col-span-2 col-span-1  justify-center items-center rounded-lg font-bold text-2xl">
                        <img
                            className="mx-2"
                            width={24}
                            src={process.env.PUBLIC_URL + '/img/coins/ptc.png'}
                        />
                        <div>
                            <a
                                href="https://exp-l1.jibchain.net/token/0xA9B5594F1FC4936Da6a34E260b3B9cc0de8c6A3d"
                                target="_blank"
                            >
                                Partical (PTC){' '}
                            </a>
                        </div>
                    </div>

                    <div className="flex flex-col col-span-1 mt-0 w-full text-justify">
                        Experience the unique dynamic of our NFT collection,
                        where the value escalates with each acquisition. For
                        every 100 NFTs sold, the price of JBC increases by +3
                        JBC, rewarding early adopters with better rates.
                        Additionally, each purchase with JBC grants you 1
                        Partical (PTC) token, a key to exclusive privileges
                        within our digital art ecosystem.
                    </div>

                    <div className="flex flex-col col-span-1 mt-0 w-full text-justify">
                        These PTC tokens are your gateway to acquiring new NFTs
                        in our current collection and gaining access to future
                        exclusive events and collections only available through
                        PTC Sodsai. This system is more than a purchase; it's an
                        investment in a growing community, where each NFT
                        enriches your collection and unlocks new opportunities
                        in the evolving world of digital art.
                    </div>
                </div>
            </div>

            <div className="w-full col-span-1 p-3 flex flex-col justify-start items-center drop-shadow-lg ">
                <div className="text-2xl font-bold m-2">
                    <span className="text-pink-500">Join</span> A Community
                </div>
                <a
                    href="https://www.facebook.com/groups/jibchain"
                    target="_blank"
                >
                    <div className="flex flex-row rounded-md whitespace-nowrap overflow-hidden  font-bold p-3   transition-all duration-300">
                        <img
                            className="mx-2"
                            width={24}
                            src={process.env.PUBLIC_URL + '/img/fbicon.png'}
                        />
                        <div>JBC - JIB Chain Community</div>
                    </div>
                </a>
            </div>

            <div className="w-full col-span-1  p-6 flex flex-col justifyitem-center items-center drop-shadow-lg ">
                <div className="text-2xl font-bold m-2">
                    Build <span className="text-pink-500"> on</span>
                </div>
                <a href="https://jibchain.net/" target="_blank">
                    <div className="flex flex-col justify-center items-center rounded-lg font-bold  p-3 bg-neutral-100/0 transition-all duration-300">
                        <img
                            className="mx-2"
                            width={56}
                            src={process.env.PUBLIC_URL + '/img/jbc-badge.png'}
                        />
                        <div>JBC Blockchain Network</div>
                    </div>
                </a>
            </div>

            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
                className="flex flex-col"
            >
                <div className="bg-white m-6 text-center text-gray-900 text-lg p-3 rounded-lg md:w-[512px] w-full display-linebreak">
                    <span className="absolute text-black/5">#{state}</span>
                    {state == 1 ? (
                        <>
                            <div className="mt-5">{info}</div>
                            <br />
                            <BiLoaderAlt
                                size={56}
                                className="w-full inherit animate-spin text-gray-600 mt-2"
                            />
                        </>
                    ) : null}

                    {state == 2 ? (
                        !!nfts && nfts.length > 0 ? (
                            <>
                                <br />
                                <div className="mb-5">{info}</div>
                                <NFTGallery
                                    _nfts={nfts}
                                    _buyWithJBC={payBy == 'JBC'}
                                />
                                <Link to="/MyWallet">
                                    <span className="cursor-pointer flex flex-row font-bold mt-3 -mb-2 justify-center items-center px-2  rounded-lg hover:bg-red-400 md:transition-all p-1">
                                        <MdOutlineAccountBalanceWallet
                                            className="items-center mr-1"
                                            size={28}
                                        />
                                        View on NFTWallet
                                    </span>
                                </Link>

                                <div
                                    onClick={() => {
                                        setInfo('')
                                        setState(0)
                                        handleClose()
                                        setNfts([])
                                    }}
                                    className="w-full translate-y-6 bg-blue-400 text-white col-span-2 md:col-span-4 hover:bg-red-500 text-3xl font-bold p-2 rounded-xl md:transition-all shadow-lg cursor-pointer"
                                >
                                    Done
                                </div>
                            </>
                        ) : (
                            <>
                                {info}
                                <br />
                                <BiLoaderAlt
                                    size={56}
                                    className="w-full inherit animate-spin text-gray-600 mt-5"
                                />
                            </>
                        )
                    ) : null}

                    {state == 3 ? (
                        <>
                            <div className="mt-5 mb-2">{info}</div>
                            <SteplineInfuse step={1} mode={'Buy'} />
                            <br />
                            <BiLoaderAlt
                                size={56}
                                className="w-full inherit animate-spin text-gray-600 mt-2"
                            />
                        </>
                    ) : null}

                    {state == 4 ? (
                        <>
                            <div className="mt-5 mb-2">{info}</div>
                            <SteplineInfuse step={3} mode={'Buy'} />
                            <br />
                            <BiLoaderAlt
                                size={56}
                                className="w-full inherit animate-spin text-gray-600 mt-2"
                            />
                        </>
                    ) : null}

                    <br />
                </div>
            </Backdrop>
        </div>
    )
}

export default Sodsai
