import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ethers } from 'ethers'
import { useSelector } from 'react-redux'
import AnimatedNumber from 'animated-number-react'
import Backdrop from '@mui/material/Backdrop'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { BiImage, BiMailSend } from 'react-icons/bi'
import Rate from './Rate'
import {
    MdVerified,
    MdOutlineSell,
    MdOutlineAccountBalanceWallet,
} from 'react-icons/md'
import { FaWindowClose } from 'react-icons/fa'
import AddNetwork from './AddNetwork'
import axios from 'axios'
/* Verify Contract */
let verifyContract = []
verifyContract.push('0x771De487691B2365De462F011c5Ad2e866753A26'.toLowerCase())
verifyContract.push('0x4B8cDbf7CFE5e2D4282D4f4517D44b13a6CC8016'.toLowerCase())
verifyContract.push('0x8934eAb9c9215dE2A129D982905EcDFD1BA2fDC8'.toLowerCase())

const PTC = process.env.REACT_APP_PTC

//const NFTDATA = require('./nftdata.js')
const formatValue = (value) => `${separator(Number(value).toFixed(2))}`
function separator(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const isMetaMaskInstalled = () => {
    const { ethereum } = window
    return Boolean(ethereum && ethereum.isMetaMask)
}

function ViewWallet() {
    const [error, setError] = useState()
    const [info, setInfo] = useState()
    const [account, setAccount] = useState()
    const [state, setState] = useState(0)
    const [openBackdrop, setOpenBackdrop] = useState(false)
    const [nfts, setNfts] = useState({})
    const [nftRate, setNftRate] = useState()
    const [nftBirthday, setNftBirthday] = useState()
    const [searchTerm, setSearchTerm] = useState('')
    const [nftImg, setNftImg] = useState()
    const [nftID, setNftID] = useState()
    const [nftName, setNftName] = useState()
    const [contractName, setContractType] = useState()
    const [attributes, setAttributes] = useState()
    const [listID, setListID] = useState()
    const [loading, setLoading] = useState(true)

    const wc = useSelector((state) => state.walletConnect.value)
    const { walletAddress } = useParams()
    let provider
    let ethereum = window.ethereum
    let tempSigner

    if (!!wc.account) {
        ethereum = wc.provider
        provider = wc.web3Provider
        tempSigner = provider.getSigner()
        ethereum.on('accountsChanged', (accounts) => setState(0))
    } else if (isMetaMaskInstalled()) {
        ethereum = window.ethereum
        provider = new ethers.providers.Web3Provider(ethereum)
        tempSigner = provider.getSigner()
        ethereum.on('accountsChanged', (accounts) => setState(0))
    }

    useEffect(() => {
        console.clear()
        if (!!wc.account || isMetaMaskInstalled()) {
            getCurrentAccount()
            console.log('walletAddress:', walletAddress)
            getAssetsOfAddress(walletAddress)
        }

        return () => {
            provider = null
            tempSigner = null
        }
    }, [wc.account, account])

    // Function to handle search input change
    const handleSearchChange = (e) => {
        const value = e.target.value
        // Allow only numbers and limit to 5 digits
        if (value === '' || (/^\d+$/.test(value) && value.length <= 5)) {
            setSearchTerm(value)
        }
    }

    // Filtered NFTs based on search term
    const filteredNfts = searchTerm
        ? nfts.filter((nft) => nft.identifier.includes(searchTerm))
        : nfts
    /////////////////////////////////////////////////////////////

    async function getAssetsOfAddress(addr) {
        const parseNFTMetadata = async function (
            metadata,
            ipfs_gateway = 'https://ipfs.8api.sh/ipfs/'
        ) {
            const applyIPFSGateway = function (uri) {
                if (uri instanceof Array) {
                    return uri.map((i) => applyIPFSGateway(i))
                } else if (typeof uri === 'object') {
                    Object.keys(uri).forEach((k) => {
                        uri[k] = applyIPFSGateway(uri[k])
                    })
                    return uri
                } else if (typeof uri === 'string') {
                    return uri.replace('ipfs://', ipfs_gateway)
                } else {
                    return uri
                }
            }
            metadata = applyIPFSGateway(metadata)
            try {
                const URI = new URL(metadata)
                const response = await axios.get(metadata)
                return applyIPFSGateway(response.data)
            } catch (e) {
                return metadata
            }
        }

        let skiploop = 0
        let erc721length = 0
        let verifyNfts = []
        setLoading(true)
        do {
            try {
                const response = await axios.post(
                    'https://graph.jibchain.net/subgraphs/name/jbc/all',
                    {
                        query: `{
                                account(id: "${addr}") {
                                    id
                                    ERC721tokens (skip: ${skiploop}){
                                        contract {
                                            id
                                            name
                                            symbol
                                        }
                                        uri
                                        identifier
                                    }
                                }
                            }`,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )

                const data = response.data.data
                    ? response.data.data.account
                    : null
                erc721length = data ? data.ERC721tokens.length : 0
                console.log('Fetched ERC721 tokens count:', erc721length)

                if (data) {
                    data.address = data.id
                    const newVerifyNfts = data.ERC721tokens.filter((item) =>
                        verifyContract.includes(item.contract.id)
                    )

                    // Fetch and apply metadata for each NFT
                    for (let nft of newVerifyNfts) {
                        nft.metadata = await parseNFTMetadata(nft.uri)
                    }

                    if (newVerifyNfts.length > 0) {
                        verifyNfts = [...verifyNfts, ...newVerifyNfts]
                        setNfts(verifyNfts)
                        console.log('Verified NFTs updated:', verifyNfts)
                        console.log('skip:', skiploop)
                    }

                    skiploop += 100
                }
            } catch (error) {
                console.error('Error fetching data:', error)
                break // Exit loop on error
            }
        } while (erc721length === 100)
        setLoading(false)

        return verifyNfts.length > 0 ? verifyNfts : null
    }

    ////////////////////////////////////////////////////////////

    async function getCurrentAccount() {
        const accounts = await ethereum.request({
            method: 'eth_accounts',
        })
        setAccount(accounts[0])
        return accounts[0]
    }

    /*//////////////////////////////////////////////////////////////////*/
    function Lists({
        _nftId,
        nftAddr,
        nftName,
        image,
        attributes,
        contractName,
        description,
    }) {
        if (
            (typeof attributes === 'object' ||
                typeof attributes === 'function') &&
            attributes !== null
        ) {
            attributes = Object.entries(attributes)
        }

        let _rate = 'none'
        let _birthday = null
        for (const [key, attribute] of Object.entries(attributes)) {
            if (attribute[1].trait_type === 'Rate') {
                _rate = attribute[1].value // Assign the rate
            }
            if (attribute[1].display_type === 'date') {
                // Convert Unix timestamp to human-readable date
                const date = new Date(parseInt(attribute[1].value) * 1000) // Unix timestamp is in seconds
                _birthday = date.toISOString().substring(0, 10) // or use any other format you prefer
            }
        }

        return (
            <div className="aspect-w-6 aspect-h-6 md:aspect-h-8 lg:aspect-h-7 col-span-1 md:transition-all duration-500 m-2 drop-shadow-xl">
                <div className="aspect-content item-bg  flex flex-col max-h-[300px] lg:max-h-[330px] justify-center overflow-hidden items-center rounded-xl hover:border border-pink-600 md:transition-all">
                    <div
                        onClick={() => {
                            setInfo(walletAddress)
                            setNftImg(image)
                            setNftID(_nftId)
                            setNftName(nftName)
                            setAttributes(attributes)
                            setNftRate(_rate)
                            setNftBirthday(_birthday)
                            setContractType(contractName)
                            setState(1)
                            setOpenBackdrop(true)
                            setListID(listID)
                        }}
                        className="relative h-[300px] overflow-hidden cursor-pointer"
                    >
                        <Rate rate={_rate} />
                        {image == null ? (
                            <BiImage
                                className="items-centers text-white/20 place-items-center mt-16"
                                size={96}
                            />
                        ) : (
                            <img
                                className="transition-all duration-500 drop-shadow-xl hover:scale-110 "
                                src={image}
                            />
                        )}
                    </div>
                    <div className="w-full bg-white relative translate-y-0 text-gray-300 p-3 pt-5 justify-center text-center md:transition-all duration-400 ">
                        <span className="flex flex-row text-pink-600 font-bold -mt-3 items-center text-sm">
                            {contractName}
                            {verifyContract.includes(nftAddr.toLowerCase()) ? ( // Check verify contract
                                <MdVerified className="text-green-500 " />
                            ) : null}
                        </span>
                        <span
                            className="flex flex-row  text-gray-800 text-sm overflow-hidden"
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            {nftName == null ? 'Undefined' : nftName}
                        </span>
                        <span
                            className="flex flex-row text-gray-400 text-xs overflow-hidden"
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            {description == null ? 'Undefined' : description}
                        </span>
                        <div
                            className="w-full flex flex-row text-gray-500 justify-between my-0"
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            <span className="text-xs hover:text-pink-600">
                                <a
                                    href={`https://exp-l1.jibchain.net/address/${nftAddr}`}
                                    target="_blank"
                                >
                                    {nftAddr.slice(0, 5) +
                                        '....' +
                                        nftAddr.slice(-4)}
                                </a>
                            </span>
                            <span className="text-xs font-bold hover:text-pink-600">
                                <a
                                    href={`https://exp-l1.jibchain.net/token/${nftAddr}/instance/${_nftId}`}
                                    target="_blank"
                                >
                                    ID : {_nftId}
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="flex flex-col w-full justifyitem-center items-center mt-20 select-none">
                <AddNetwork PTC={PTC} />

                <div className="border-0 w-full md:w-5/6 grid grid-cols-2 md:grid-cols-4 xl:grid-cols-5 gap-1 p-0 md:p-5 mt-10 rounded-xl  bg-red-500/10 bg-opacity-70 backdrop-blur-xl transition-all">
                    <div className="md:col-span-4 col-span-2 xl:col-span-5 bg-red-700/30 text-white drop-shadow-md rounded-lg px-2 mt-5 font-bold text-xl flex flex-row justify-between items-center">
                        <div className="w-full flex justify-between items-center">
                            <a
                                href={`https://exp-l1.jibchain.net/address/${walletAddress}`}
                                target="_blank"
                            >
                                <div className="flex flex-row items-center ">
                                    Owner :{' '}
                                    <MdOutlineAccountBalanceWallet className="mx-1" />
                                    <span className="hover:text-pink-200">
                                        {walletAddress.slice(0, 7) +
                                            '....' +
                                            walletAddress.slice(-7)}
                                    </span>
                                </div>
                                <div className="-mt-2">
                                    Total : {nfts.length} NFTs
                                </div>
                            </a>
                            <input
                                type="text"
                                pattern="[0-9]*"
                                maxLength={5}
                                autoComplete="off"
                                placeholder="NFT ID"
                                className="w-1/4 h-10 rounded-lg border-0 text-center bg-red-100/60 text-red-800 font-bold md:text-3xl text-xl focus:outline-none tracking-widest"
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>

                    {/* ...other elements... */}
                    {filteredNfts.length > 0 ? (
                        filteredNfts.map((x, i) => {
                            const metaData = x.metadata

                            return (
                                <Lists
                                    key={i * 10}
                                    listID={x.identifier}
                                    amount={1}
                                    _nftId={x.identifier}
                                    nftOwner={'sss'}
                                    nftName={
                                        !!metaData
                                            ? metaData.name
                                            : 'Undefinded'
                                    }
                                    contractName={x.contract.name}
                                    nftAddr={x.contract.id}
                                    image={
                                        !!metaData
                                            ? metaData.image
                                            : process.env.PUBLIC_URL +
                                              '/img/noimag.png'
                                    }
                                    attributes={
                                        !!metaData
                                            ? metaData.attributes
                                            : { NA: 'No Data' }
                                    }
                                    description={
                                        !!metaData
                                            ? metaData.description
                                            : { NA: 'No Data' }
                                    }
                                />
                            )
                        })
                    ) : (
                        <div className="col-span-full text-center text-white">
                            No NFTs found.
                        </div>
                    )}
                </div>

                {/*///////////////////// //////////////////////////// */}
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={(openBackdrop && state != 0) || loading}
                    className="flex flex-col"
                >
                    {state != 0 ? (
                        <div className="modal-bg2 flex backdrop-blur-md flex-col justify-center items-center m-4 text-center text-gray-900 text-lg p-3 rounded-lg md:w-3/5 w-full max-w-[712px] display-linebreak">
                            <div className="w-full flex flex-row justify-between">
                                <span className="mx-3 text-black/10">
                                    #{state}
                                </span>
                                <FaWindowClose
                                    fontSize={40}
                                    color="#cc0e00"
                                    className={`md:transition-all duration-100 drop-shadow-lg cursor-pointer hover:scale-125`}
                                    onClick={() => {
                                        setError('')
                                        setState(0)
                                    }}
                                />
                            </div>
                            <div className="w-full flex flex-row justify-center -mt-5 my-3">
                                <div className="text-gray-700 md:text-lg text-sm font-bold mt-5 md:mt-0">
                                    {info}
                                    <span className="text-rose-500 font-bold">
                                        {error}
                                    </span>
                                </div>
                            </div>

                            {state == 1 ? (
                                <>
                                    <div className="w-full md:w-4/5 rounded-lg drop-shadow-md flex flex-col justify-center py-0 items-center modal-bg2 max-w-[512px] overflow-hidden">
                                        <span className="w-full flex flex-col justify-center">
                                            {nftImg == null ? (
                                                <BiImage
                                                    className="items-centers  text-white/20  place-items-center mx-16 mt-14"
                                                    size={120}
                                                />
                                            ) : (
                                                <div className="w-full flex justify-center transition-all duration-500 max-w-[512px] max-h-[512px] overflow-hidden">
                                                    <img
                                                        src={nftImg}
                                                        className="w-full h-full scale-110 blur-md absolute rounded-lg transition-all"
                                                    />
                                                    <img
                                                        src={nftImg}
                                                        className="max-w-full relative max-h-full transition-all"
                                                        style={{
                                                            objectFit:
                                                                'contain',
                                                        }}
                                                    />
                                                </div>
                                            )}

                                            <span className="w-full absolute flex flex-col hover:-translate-y-48 top-[465px]  tratransition-all duration-500 justify-center pb-10 p-3 bg-black/30 text-gray-200 rounded-b-lg text-md">
                                                <span className="w-full text-center md:text-right text-2xl font-bold -mt-1">
                                                    <Rate rate={nftRate} />
                                                    {nftName}
                                                </span>
                                                <div className="w-full flex flex-row justify-between">
                                                    <span className="font-bold"></span>

                                                    <span className="flex flex-row text-gray-200 ">
                                                        {contractName}
                                                    </span>
                                                </div>

                                                <div className="w-full flex flex-row justify-between">
                                                    <span className="font-bold"></span>
                                                    <span className="flex flex-row text-gray-200">
                                                        ID : {nftID}
                                                    </span>
                                                </div>

                                                <span className="w-full text-left font-bold mb-1">
                                                    Attributes
                                                </span>
                                                {!!attributes ? (
                                                    <div className="bg-gray-400/20 drop-shadow-lg flex flex-col rounded-lg mt-0 p-2 text-left text-white text-sm">
                                                        {attributes.map(
                                                            (x, i) => (
                                                                <div
                                                                    key={
                                                                        500 * i
                                                                    }
                                                                    className="flex flex-row justify-between"
                                                                >
                                                                    <span className="w-1/4">
                                                                        {
                                                                            x[1]
                                                                                .trait_type
                                                                        }
                                                                    </span>
                                                                    <span className="w-3/4 overflow-hidden">
                                                                        {x[1]
                                                                            .display_type ==
                                                                            'date' &&
                                                                        !!nftBirthday
                                                                            ? nftBirthday
                                                                            : x[1]
                                                                                  .value}
                                                                    </span>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div />
                                                )}
                                            </span>
                                        </span>
                                    </div>
                                    <div className=" w-full max-w-[512px] flex flex-row justify-end">
                                        <div className="w-1/3 grayscale opacity-50 self-end flex flex-row justify-center bg-red-100 text-red-500 text-3xl font-bold p-2 m-2 my-4 rounded-xl md:transition-all shadow-lg  items-center">
                                            <MdOutlineSell className="-ml-3 mr-2 " />
                                            Sell
                                        </div>
                                        <div className="w-1/3 grayscale opacity-50 self-end flex flex-row justify-center bg-yellow-50 text-yellow-500 text-3xl font-bold p-2 m-2 my-4 rounded-xl  md:transition-all shadow-lg items-center">
                                            <BiMailSend className="-ml-3 mr-2" />{' '}
                                            Send
                                        </div>
                                    </div>
                                </>
                            ) : null}

                            <br />
                        </div>
                    ) : null}
                    {loading ? (
                        <div className="bg-white px-10 text-gray-500  mt-2 text-xl p-2 rounded-md md:col-span-4 col-span-2 xl:col-span-5 ">
                            <div className="ldio-zce7re3830o  flex flex-row justify-center items-center animate-pulse ">
                                <img
                                    className="mr-4"
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/loadnganimate2.svg'
                                    }
                                />
                                <div>Loading ...</div>
                            </div>
                        </div>
                    ) : null}

                    <br />
                </Backdrop>

                {/*///////////////////// THE PRIZE //////////////////////////// */}
            </div>
        </>
    )
}

export default ViewWallet
